import { CALL_API, Schemas } from '../middleware/api'

export const ADD_TO_BASKET = 'ADD_TO_BASKET'
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET'
export const REMOVE_ALL_FROM_BASKET = 'REMOVE_ALL_FROM_BASKET'
export const BASKET_FAILURE = 'BASKET_FAILURE'

export const addToCart= (id)=>{
    return{
        type: ADD_TO_BASKET,
        id
    }
}

export const removeFromCart= (id)=>{
    return{
        type: REMOVE_FROM_BASKET,
        id
    }
}

export const removeAllFromCart= ()=>{
    return{
        type: REMOVE_ALL_FROM_BASKET
    }
}
 


export const VOUCHER_REQUEST = 'VOUCHER_REQUEST'
export const VOUCHER_SUCCESS = 'VOUCHER_SUCCESS'
export const VOUCHER_FAILURE = 'VOUCHER_FAILURE'

const fetchCheckVoucherCode = (voucherCode, testIds, orderCookieId, subscriptionId = 0, globalPlusTests=[], customerId, guestCustomerId) => ({
  voucherCode,
  testIds,
  orderCookieId,
  subscriptionId,
  globalPlusTests,
  customerId,
  guestCustomerId,
  [CALL_API]: {
    types: [ VOUCHER_REQUEST, VOUCHER_SUCCESS, VOUCHER_FAILURE ],
    endpoint: 'check-voucher',
    schema: null,
    method: 'POST', 
    body: {
      voucherCode: voucherCode,
      testIds: testIds,
      orderCookieId: orderCookieId,
      subscriptionId: subscriptionId,
      globalPlusTests: globalPlusTests,
      customerId: customerId,
      guestCustomerId: guestCustomerId,
    }
  }
})

export const checkVoucherCode = (voucherCode, testIds, orderCookieId, subscriptionId = 0, globalPlusTests=[], customerId, guestCustomerId) => (dispatch, getState) => {
  return dispatch(fetchCheckVoucherCode(voucherCode, testIds, orderCookieId, subscriptionId, globalPlusTests, customerId, guestCustomerId))
};



export const BASKET_REQUEST = 'BASKET_REQUEST';
export const BASKET_SUCCESS = 'BASKET_SUCCESS'; 

const fetchAddToBasket = (testId, orderCookieId, customerId, purchaseGroup, bundleGroup, forthEdge, voucherCode, phlebotomy, update, deliveryOption, partnerId, qty) => ({
  testId,
  orderCookieId,
  customerId,
  purchaseGroup,
  bundleGroup,
  forthEdge,
  voucherCode,
  phlebotomy,
  update,
  deliveryOption,
  partnerId,
  qty,
  [CALL_API]: {
    types: [BASKET_REQUEST, BASKET_SUCCESS, BASKET_FAILURE],
    endpoint: 'basket/add-to-cart',
    method: 'POST',
    body: {
      testId,
      orderCookieId,
      customerId,
      purchaseGroup,
      bundleGroup,
      forthEdge,
      voucherCode,
      phlebotomy,
      update,
      deliveryOption,
      partnerId,
      qty
    },
  },
});

export const loadAddToBasket = (testId, orderCookieId, customerId, purchaseGroup, bundleGroup, forthEdge, voucherCode, phlebotomy, update, deliveryOption, partnerId, qty) => (dispatch) => {
  return dispatch(fetchAddToBasket(testId, orderCookieId, customerId, purchaseGroup, bundleGroup, forthEdge, voucherCode, phlebotomy, update, deliveryOption, partnerId, qty));
};


const fetchClearBasket = (orderCookieId) => ({
  orderCookieId,
  [CALL_API]: {
    types: [BASKET_REQUEST, BASKET_SUCCESS, BASKET_FAILURE],
    endpoint: 'basket/clear-basket',
    method: 'POST',
    body: {
      orderCookieId
    },
  },
});

export const loadClearBasket = (orderCookieId) => (dispatch) => {
  return dispatch(fetchClearBasket(orderCookieId));
};


const fetchRemoveFromBasket = (testId, orderCookieId, partnerId) => ({
  testId,
  orderCookieId,
  partnerId,
  [CALL_API]: {
    types: [BASKET_REQUEST, BASKET_SUCCESS, BASKET_FAILURE],
    endpoint: 'basket/remove-from-cart',
    method: 'POST',
    body: {
      testId,
      orderCookieId,
      partnerId,
    },
  },
});

export const loadRemoveFromBasket = (testId, orderCookieId, partnerId) => (dispatch) => {
  return dispatch(fetchRemoveFromBasket(testId, orderCookieId, partnerId));
};



export const BASKET_UPDATE_REFERENCE_REQUEST = 'BASKET_UPDATE_REFERENCE_REQUEST';
export const BASKET_UPDATE_REFERENCE_SUCCESS = 'BASKET_UPDATE_REFERENCE_SUCCESS'; 
export const BASKET_UPDATE_REFERENCE_FAILURE = 'BASKET_UPDATE_REFERENCE_FAILURE'; 

const fetchUpdateBasketReference = (userId, subscriptionOrder, orderCookieId) => ({
  userId,
  subscriptionOrder,
  orderCookieId,
  [CALL_API]: {
    types: [BASKET_UPDATE_REFERENCE_REQUEST, BASKET_UPDATE_REFERENCE_SUCCESS, BASKET_UPDATE_REFERENCE_FAILURE],
    endpoint: '/basket/update-order-reference',
    method: 'POST',
    body: {
      userId,
      subscriptionOrder,
      orderCookieId,
    },
  },
});

export const loadUpdateBasketReference = (userId, subscriptionOrder, orderCookieId) => (dispatch) => {
  return dispatch(fetchUpdateBasketReference(userId, subscriptionOrder, orderCookieId));
};



export const CART_REQUEST = 'CART_REQUEST'
export const CART_SUCCESS = 'CART_SUCCESS'
export const CART_FAILURE = 'CART_FAILURE'

const fetchGetCart = (orderCookieId, overrideOrderId, partnerId, partnerCheckoutOverride=0) => ({
  orderCookieId, 
  overrideOrderId,
  partnerId,
  partnerCheckoutOverride,
  [CALL_API]: {
    types: [ CART_REQUEST, CART_SUCCESS, CART_FAILURE ],
    endpoint: `/basket/get-cart/${orderCookieId}/${overrideOrderId}/${partnerId}/${partnerCheckoutOverride}`,
    schema: Schemas.CART_ARRAY
  }
})

export const loadGetCart = (orderCookieId, overrideOrderId, partnerId, partnerCheckoutOverride=0) => (dispatch, getState) => {
  const cart = getState().entities.cart;
 
  if (Object.keys(cart).length > 0) {
    Object.keys(cart).map(function(key) {

      delete cart[key];

    })  
  }
  return dispatch(fetchGetCart(orderCookieId, overrideOrderId, partnerId, partnerCheckoutOverride))
}


export const UPDATE_PAYMENT_INTENT_REQUEST = 'UPDATE_PAYMENT_INTENT_REQUEST'
export const UPDATE_PAYMENT_INTENT_SUCCESS = 'UPDATE_PAYMENT_INTENT_SUCCESS'
export const UPDATE_PAYMENT_INTENT_FAILURE = 'UPDATE_PAYMENT_INTENT_FAILURE'

const fetchPaymentIntent = (body) => {
  const hostname = window.location.hostname;

  const isForthwithLife = hostname.endsWith('forthwithlife.co.uk');
  const isSportsBloodTests = hostname.endsWith('sportsbloodtests.co.uk');

  // If we're on a subdomain of either, force partnerId to 0
  if (isForthwithLife || isSportsBloodTests) {
    body = {
      ...body,
      partnerId: 0,
      checkoutFromPartner: 0
    };
  }

  return {
    [CALL_API]: {
      types: [ UPDATE_PAYMENT_INTENT_REQUEST, UPDATE_PAYMENT_INTENT_SUCCESS, UPDATE_PAYMENT_INTENT_FAILURE ],
      endpoint: '/checkout/payment-intent',
      schema: null,
      method: 'POST', 
      body
    }
  };
};

export const updatePaymentIntent = (body) => (dispatch, getState) => {
  return dispatch(fetchPaymentIntent(body))
};



export const UPDATE_PAYMENT_INTENT_REQUEST_TEST = 'UPDATE_PAYMENT_INTENT_REQUEST_TEST'
export const UPDATE_PAYMENT_INTENT_SUCCESS_TEST = 'UPDATE_PAYMENT_INTENT_SUCCESS_TEST'
export const UPDATE_PAYMENT_INTENT_FAILURE_TEST = 'UPDATE_PAYMENT_INTENT_FAILURE_TEST'

const fetchPaymentIntentTest= (body) => ({
  [CALL_API]: {
    types: [ UPDATE_PAYMENT_INTENT_REQUEST_TEST, UPDATE_PAYMENT_INTENT_SUCCESS_TEST, UPDATE_PAYMENT_INTENT_FAILURE_TEST ],
    endpoint: '/checkout/payment-intent-test',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePaymentIntentTest = (body) => (dispatch, getState) => {
  return dispatch(fetchPaymentIntentTest(body))
};





export const PARTNER_CLINIC_REQUEST = 'PARTNER_CLINIC_REQUEST'
export const PARTNER_CLINIC_SUCCESS = 'PARTNER_CLINIC_SUCCESS'
export const PARTNER_CLINIC_FAILURE = 'PARTNER_CLINIC_FAILURE'

const fetchPartnerClinics = () => ({
  [CALL_API]: {
    types: [ PARTNER_CLINIC_REQUEST, PARTNER_CLINIC_SUCCESS, PARTNER_CLINIC_FAILURE ],
    endpoint: `/basket/get-partner-clinics`,
    schema: Schemas.PARTNER_CLINIC_ARRAY
  }
})

export const loadPartnerClinics = () => (dispatch, getState) => {
  return dispatch(fetchPartnerClinics())
}

export const ADD_CREDIT_REQUEST = 'ADD_CREDIT_REQUEST'
export const ADD_CREDIT_SUCCESS = 'ADD_CREDIT_SUCCESS'
export const ADD_CREDIT_FAILURE = 'ADD_CREDIT_FAILURE'

const fetchAddCredit = (customerId, credit) => ({
  [CALL_API]: {
    types: [ ADD_CREDIT_REQUEST, ADD_CREDIT_SUCCESS, ADD_CREDIT_FAILURE ],
    endpoint: `/secure/checkout/add-credit/${credit}/${customerId}`,
    schema: Schemas.CART_ARRAY
  }
})

export const addCredit = (customerId, credit) => (dispatch, getState) => {
  return dispatch(fetchAddCredit(customerId, credit))
}


export const PARTNER_BASKET_REQUEST = 'PARTNER_BASKET_REQUEST'
export const PARTNER_BASKET_SUCCESS = 'PARTNER_BASKET_SUCCESS'
export const PARTNER_BASKET_FAILURE = 'PARTNER_BASKET_FAILURE'

const fecthPartnerCheckout = (customerId, partnerId, testId, cookie, orderVoucherId, phlebotomyOption) => ({
  [CALL_API]: {
    types: [ PARTNER_BASKET_REQUEST, PARTNER_BASKET_SUCCESS, PARTNER_BASKET_FAILURE ],
    endpoint: `checkout/create-partner-basket/${customerId}/${partnerId}/${testId}/${cookie}/${orderVoucherId}/${phlebotomyOption}`,
    schema: null
  }
})

export const partnerCheckout = (customerId, partnerId, testId, cookie, orderVoucherId, phlebotomyOption) => (dispatch, getState) => {
  return dispatch(fecthPartnerCheckout(customerId, partnerId, testId, cookie, orderVoucherId, phlebotomyOption))
}

const fetchCreatePartnerBasket = (customerId, testId, cookie, orderVoucherId, phlebotomyOption) => ({
  customerId, testId, cookie, orderVoucherId, phlebotomyOption,
  [CALL_API]: {
    types: [ PARTNER_BASKET_REQUEST, PARTNER_BASKET_SUCCESS, PARTNER_BASKET_FAILURE ],
    endpoint: `secure/basket/create-partner-basket`,
    schema: null,
    method: 'POST',
    body: {
      customerId, testId, cookie, orderVoucherId, phlebotomyOption
    },
  }
})

export const createPartnerBasket = (customerId, testId, cookie, orderVoucherId, phlebotomyOption) => (dispatch, getState) => {
  return dispatch(fetchCreatePartnerBasket(customerId, testId, cookie, orderVoucherId, phlebotomyOption))
}


export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE'

const fetchUpdateOrderCustomer = (customerId, orderCookieId) => ({
  customerId,
  orderCookieId,
  [CALL_API]: {
    types: [ UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE ],
    endpoint: `/basket/update-order-customer`,
    schema: null,
    method: 'POST',
    body: {
      customerId,
      orderCookieId
    },
  }
})

export const updateOrderCustomer= (customerId, partnerId, testId, cookie, orderVoucherId, phlebotomyOption) => (dispatch, getState) => {
  return dispatch(fetchUpdateOrderCustomer(customerId, partnerId, testId, cookie, orderVoucherId, phlebotomyOption))
}

export const GET_DELIVERY_OPTIONS_REQUEST = 'GET_DELIVERY_OPTIONS_REQUEST'
export const GET_DELIVERY_OPTIONS_SUCCESS = 'GET_DELIVERY_OPTIONS_SUCCESS'
export const GET_DELIVERY_OPTIONS_FAILURE = 'GET_DELIVERY_OPTIONS_FAILURE'

const fetchDeliveryOptions = () => ({
  [CALL_API]: {
    types: [ GET_DELIVERY_OPTIONS_REQUEST, GET_DELIVERY_OPTIONS_SUCCESS, GET_DELIVERY_OPTIONS_FAILURE ],
    endpoint: `checkout/get-delivery-options`,
    schema: null
  }
})

export const getDeliveryOptions = () => (dispatch, getState) => {
  return dispatch(fetchDeliveryOptions())
}

export const UPDATE_DELIVERY_OPTION_REQUEST = 'UPDATE_DELIVERY_OPTION_REQUEST'
export const UPDATE_DELIVERY_OPTION_SUCCESS = 'UPDATE_DELIVERY_OPTION_SUCCESS'
export const UPDATE_DELIVERY_OPTION_FAILURE = 'UPDATE_DELIVERY_OPTION_FAILURE'

const fetchUpdateOrderWithDeliveryOption = (orderCookieId, deliveryOption, phlebotomyOption) => ({
  orderCookieId,
  deliveryOption,
  phlebotomyOption,
  [CALL_API]: {
    types: [ UPDATE_DELIVERY_OPTION_REQUEST, UPDATE_DELIVERY_OPTION_SUCCESS, UPDATE_DELIVERY_OPTION_FAILURE ],
    endpoint: `basket/update-delivery-option`,
    schema: null,
    method: 'POST',
    body: {
      orderCookieId,
      deliveryOption,
      phlebotomyOption
    },
  }
})

export const updateOrderWithDeliveryOption = (orderCookieId, deliveryOption, phlebotomyOption) => (dispatch, getState) => {
  return dispatch(fetchUpdateOrderWithDeliveryOption(orderCookieId, deliveryOption, phlebotomyOption))
}

export const LOAD_SCORE_REQUEST = 'LOAD_SCORE_REQUEST'
export const LOAD_SCORE_SUCCESS = 'LOAD_SCORE_SUCCESS'
export const LOAD_SCORE_FAILURE = 'LOAD_SCORE_FAILURE'

const fetchLoadScoreRanges = (testType = 0) => ({
  [CALL_API]: {
    types: [ LOAD_SCORE_REQUEST, LOAD_SCORE_SUCCESS, LOAD_SCORE_FAILURE ],
    endpoint: `checkout/load-score-ranges/${testType}`,
    schema: null
  }
})

export const loadScoreRanges = (testType) => (dispatch, getState) => {
  return dispatch(fetchLoadScoreRanges(testType))
}

export const LOAD_QUESTIONS_REQUEST = 'LOAD_QUESTIONS_REQUEST'
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS'
export const LOAD_QUESTIONS_FAILURE = 'LOAD_QUESTIONS_FAILURE'

const fetchCheckoutQuestions = (body) => ({
  [CALL_API]: {
    types: [ LOAD_QUESTIONS_REQUEST, LOAD_QUESTIONS_SUCCESS, LOAD_QUESTIONS_FAILURE ],
    endpoint: `/get-checkout-questions`,
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const loadCheckoutQuestions = (body) => (dispatch, getState) => {

  const questionsList = getState().entities.questionsList;
  if (Object.keys(questionsList).length > 0) {
    Object.keys(questionsList).map(function(key) {

      delete questionsList[key];

    })  
  }
  return dispatch(fetchCheckoutQuestions(body))
};

export const SET_DELIVERY_OPTIONS_REQUEST = "SET_DELIVERY_OPTIONS_REQUEST"
export const SET_DELIVERY_OPTIONS_SUCCESS = "SET_DELIVERY_OPTIONS_SUCCESS"
export const SET_DELIVERY_OPTIONS_FAILURE = "SET_DELIVERY_OPTIONS_FAILURE"

export const setDeliveryOptions = (deliveryOptions) => (dispatch, getState) => {
  dispatch({
    type: SET_DELIVERY_OPTIONS_REQUEST
  })

  try {
    dispatch({
      type: SET_DELIVERY_OPTIONS_SUCCESS,
      response: deliveryOptions
    })
  } catch (error) {
    dispatch({
      type: SET_DELIVERY_OPTIONS_FAILURE,
      error: error
    })
  }
}




export const SAVE_PAYLOAD_REQUEST = 'SAVE_PAYLOAD_REQUEST'
export const SAVE_PAYLOAD_SUCCESS = 'SAVE_PAYLOAD_SUCCESS'
export const SAVE_PAYLOAD_FAILURE = 'SAVE_PAYLOAD_FAILURE'

const fetchSavePayload = (intentReference, payload) => ({
  intentReference, 
  payload,
  [CALL_API]: {
    types: [ SAVE_PAYLOAD_REQUEST, SAVE_PAYLOAD_SUCCESS, SAVE_PAYLOAD_FAILURE ],
    endpoint: '/checkout/save-payload',
    schema: null,
    method: 'POST', 
    body: {
      intentReference: intentReference,
      payload: payload
    }
  }
})

export const savePayload = (intentReference, payload) => (dispatch, getState) => {
  return dispatch(fetchSavePayload(intentReference, payload))
};


export const RETURN_PAYLOAD_REQUEST = 'RETURN_PAYLOAD_REQUEST'
export const RETURN_PAYLOAD_SUCCESS = 'RETURN_PAYLOAD_SUCCESS'
export const RETURN_PAYLOAD_FAILURE = 'RETURN_PAYLOAD_FAILURE'

const fetchGetPayload = (intentReference) => ({
  intentReference, 
  [CALL_API]: {
    types: [ RETURN_PAYLOAD_REQUEST, RETURN_PAYLOAD_SUCCESS, RETURN_PAYLOAD_FAILURE ],
    endpoint: `/checkout/return-payload/${intentReference}`,
    schema: null,
    method: 'GET'
  }
})

export const getPayload = (intentReference) => (dispatch, getState) => {
  return dispatch(fetchGetPayload(intentReference))
};

export const CHECKOUT_STAGE_TRACKING_REQUEST = 'CHECKOUT_STAGE_TRACKING_REQUEST'
export const CHECKOUT_STAGE_TRACKING_SUCCESS = 'CHECKOUT_STAGE_TRACKING_SUCCESS'
export const CHECKOUT_STAGE_TRACKING_FAILURE = 'CHECKOUT_STAGE_TRACKING_FAILURE'

const fetchCheckoutStageTracking = (body) => ({
  [CALL_API]: {
    types: [ CHECKOUT_STAGE_TRACKING_REQUEST, CHECKOUT_STAGE_TRACKING_SUCCESS, CHECKOUT_STAGE_TRACKING_FAILURE ],
    endpoint: `/send-checkout-stage`,
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const sendCheckoutStage = (body) => (dispatch, getState) => {
  return dispatch(fetchCheckoutStageTracking(body))
};