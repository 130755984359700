import _ from 'lodash';
import React, {useState, useEffect} from 'react'  
import {SidebarModal, WhyAddTheseMarkers} from '../../../components'
import styles from "./CustomiseSubscription.module.scss"; 

export default function TestUpseller(props) {  
  const tests = props.test.upsellTests;
  let lowestPrice = _.maxBy(tests, 'testPrice'); 
  
  const bespokeDomain = new URL(window.config.isDev ? `https://devbuild.${window.config.domain}` : `https://build.${window.config.domain}`)
  let customiseLink = `${bespokeDomain.href}bespoke-test/${props.testId}/${props.phlebotomyOption}`
  let editTestUrl = `${bespokeDomain.href}edit-test/${props.testId}/${props.phlebotomyOption}`
  
  let chatTest = 0;
  const voucherCode = props.voucher.voucherCode
  if(voucherCode){
    customiseLink += `/[]/${chatTest}/${voucherCode}`
    editTestUrl += `/${voucherCode}`
  } else {
    customiseLink += `/[]/${chatTest}/0`
  }

  const partnerId = localStorage.getItem('partnerPortalId');
  if(global.config.websiteId > 2 && partnerId){
    customiseLink += `/${partnerId}`
  }
  
  const sendMessage = () => {
    if(window.parent) {
      window.parent.postMessage('goToCheckout', '*');
    }
  }
 
  return (

    <div className="row mt-3 mb-5">
      <div className="col-12 px-3">
        <div id={styles.subscriptionUpseller}>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 text-center">
            {global.config.websiteId == 1 && 
              <h2 className={styles.modifyTest + " mb-4"}><img className="me-2" src="/img/boxIcon.png" /> Want more health markers?</h2>
            }
            {global.config.websiteId == 2 && 
              <h2 className={styles.modifyTest + " mb-4"}><img className="me-2" src="/img/boxIconEdge.png" /> Want to test more biomarkers?</h2>
            }
            {global.config.websiteId != 1 && global.config.websiteId != 2 && 
              <h2 className={styles.modifyTest + " mb-4"}>Want to test more biomarkers?</h2>
            }
            </div> 
             
            <div className="col-12">
              <div className="row"> 
              </div>
            </div>
            <div className="col-12 mt-1 text-center">
 
              <p className="mb-2" dangerouslySetInnerHTML={{__html: props.message ? props.message : "Add extra biomarkers to this test from as <strong>little as £7</strong> to get a better understanding of your hormone levels."}}></p>
              
             
              {props.showEdit == 1 ? (
                <div className="text-center"> 
                  <a  onClick={() => {  sendMessage() }} className="mx-0 btn btn-primary-outline w-100"  href={editTestUrl}>Modify your test</a>  
                </div>
              ) : (
                <div className="text-center">
                  <a  onClick={() => {  sendMessage() }} className="mx-0 btn btn-primary-outline w-100" href={customiseLink} >Add more markers</a>
                </div>
              )} 
            </div>
          </div> 
        </div>
      </div>
    </div>
  )
 

}