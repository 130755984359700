import { CALL_API, Schemas } from '../middleware/api'

export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILURE = 'USERS_FAILURE'

const fetchUsers = () => ({
  [CALL_API]: {
    types: [ USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE ],
    endpoint: `listallusersapiroute`,
    schema: Schemas.USER_ARRAY
  }
})

export const loadUsers = () => (dispatch, getState) => {
  const users = getState().entities.users;
  if (Object.keys(users).length > 0) {
    return null
  }

  return dispatch(fetchUsers())
}

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

const fetchUser = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ USER_REQUEST, USER_SUCCESS, USER_FAILURE ],
    endpoint: `secure/v3/users/${userId}`,
    schema: userId === 'me' ? null : Schemas.USER
  }
})

export const loadUser = (userId) => (dispatch, getState) => {
  const user = getState().entities.users[userId];
  if (user) {
    return null
  }

  return dispatch(fetchUser(userId))
}

const fetchUserForActivation = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ USER_REQUEST, USER_SUCCESS, USER_FAILURE ],
    endpoint: `/secure/users/user-to-activate/${userId}`,
    schema: null
  }
})

export const loadUserToActivate = (userId) => (dispatch, getState) => {
 
  return dispatch(fetchUserForActivation(userId))
}


export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

const fetchUpdateUserCategoryPreference = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE ],
    endpoint: 'secure/users/update-category',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserCategoryPreference = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserCategoryPreference(body))
};


export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE'

const fetchUpdateUserProfile = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_SUCCESS,UPDATE_USER_PROFILE_FAILURE ],
    endpoint: 'users/update-user-profile',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserProfile  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserProfile(body))
};


const fetchSecureUpdateUserProfile = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_SUCCESS,UPDATE_USER_PROFILE_FAILURE ],
    endpoint: 'secure/users/update-user-profile',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateSecureUserProfile  = (body) => (dispatch, getState) => {
  return dispatch(fetchSecureUpdateUserProfile(body))
};


const fetchUpdatePilotProfile = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_SUCCESS,UPDATE_USER_PROFILE_FAILURE ],
    endpoint: 'secure/users/update-pilot-profile',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePilotProfile  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdatePilotProfile(body))
};




export const UPLOAD_PROFILE_IMAGE_REQUEST = 'UPLOAD_PROFILE_IMAGE_REQUEST'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE'

const fetchUpdateUserProfilePicture = (body) => ({
  [CALL_API]: {
    types: [ UPLOAD_PROFILE_IMAGE_REQUEST, UPLOAD_PROFILE_IMAGE_SUCCESS, UPLOAD_PROFILE_IMAGE_FAILURE ],
    endpoint: 'secure/users/upload-profile-picture',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserProfilePicture  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserProfilePicture(body))
};



export const UPDATE_USER_TUTORIAL_REQUEST = 'UPDATE_USER_TUTORIAL_REQUEST'
export const UPDATE_USER_TUTORIAL_SUCCESS = 'UPDATE_USER_TUTORIAL_SUCCESS'
export const UPDATE_USER_TUTORIAL_FAILURE = 'UPDATE_USER_TUTORIAL_FAILURE'

const fetchUpdateUserTutorial = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_TUTORIAL_REQUEST, UPDATE_USER_TUTORIAL_SUCCESS, UPDATE_USER_TUTORIAL_FAILURE ],
    endpoint: 'secure/users/update-user-tutorial',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserTutorial  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserTutorial(body))
};

export const UPDATE_USER_ACTIVATION_REQUEST = 'UPDATE_USER_ACTIVATION_REQUEST'
export const UPDATE_USER_ACTIVATION_SUCCESS = 'UPDATE_USER_ACTIVATION_SUCCESS'
export const UPDATE_USER_ACTIVATION_FAILURE = 'UPDATE_USER_ACTIVATION_FAILURE'

const fetchUpdateUserActivation = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_ACTIVATION_REQUEST, UPDATE_USER_ACTIVATION_SUCCESS,UPDATE_USER_ACTIVATION_FAILURE ],
    endpoint: 'secure/users/update-user-activation',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserActivation  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserActivation(body))
};


export const QUESTIONS_REQUEST = 'QUESTIONS_REQUEST'
export const QUESTIONS_SUCCESS = 'QUESTIONS_SUCCESS'
export const QUESTIONS_FAILURE = 'QUESTIONS_FAILURE'

const fetchQuestionsList = (body) => ({
  [CALL_API]: {
    types: [ QUESTIONS_REQUEST, QUESTIONS_SUCCESS, QUESTIONS_FAILURE ],
    endpoint: `users/questionsList`,
    schema: Schemas.GET_QUESTIONS_LIST,
    method: 'POST', 
    body: body
  }
})

export const loadQuestionsList  = (body) => (dispatch, getState) => {

  const questionsList = getState().entities.questionsList;
  if (Object.keys(questionsList).length > 0) {
    Object.keys(questionsList).map(function(key) {

      delete questionsList[key];

    })  
  }
  return dispatch(fetchQuestionsList(body))
};


const fetchQuestionsListSecure = (body) => ({
  [CALL_API]: {
    types: [ QUESTIONS_REQUEST, QUESTIONS_SUCCESS, QUESTIONS_FAILURE ],
    endpoint: `/secure/users/questionsList`,
    schema: Schemas.GET_QUESTIONS_LIST,
    method: 'POST', 
    body: body
  }
})

export const loadQuestionsListSecure  = (body) => (dispatch, getState) => {
  
  const questionsList = getState().entities.questionsList;
  if (Object.keys(questionsList).length > 0) {
    Object.keys(questionsList).map(function(key) {
      
      delete questionsList[key];
      
    })  
  }
  return dispatch(fetchQuestionsListSecure(body))
};

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE'

const fetchUpdateCustomer = (body) => ({
  body,
  [CALL_API]: {
    types: [UPDATE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILURE],
    endpoint: `secure/users/update-customer-profile`,
    schema: null, 
    method: 'POST',
    body: body
  }
})

export const updateCustomerProfile = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateCustomer(body))
}

export const SEND_REMINDER_REQUEST = 'SEND_REMINDER_REQUEST'
export const SEND_REMINDER_SUCCESS = 'SEND_REMINDER_SUCCESS'
export const SEND_REMINDER_FAILURE = 'SEND_REMINDER_FAILURE'

const fetchCustomerReminder = (body) => ({
  body,
  [CALL_API]: {
    types: [SEND_REMINDER_REQUEST, SEND_REMINDER_SUCCESS, SEND_REMINDER_FAILURE],
    endpoint: `/users/send-reminder`,
    schema: null, 
    method: 'POST',
    body: body
  }
})

export const updateCustomerReminder = (body) => (dispatch, getState) => {
  return dispatch(fetchCustomerReminder(body))
}

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE'

const fetchGetUserforPartner = (userId) => ({
  
  [CALL_API]: {
    types: [GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILURE],
    endpoint: `/secure/users-for-partner/${userId}`,
    schema: null, 
  }
})

export const getUserforPartner = (userId) => (dispatch, getState) => {
  return dispatch(fetchGetUserforPartner(userId))
}

export const UPDATE_TEST_ANSWER_REQUEST = 'UPDATE_TEST_ANSWER_REQUEST'
export const UPDATE_TEST_ANSWER_SUCCESS = 'UPDATE_TEST_ANSWER_SUCCESS'
export const UPDATE_TEST_ANSWER_FAILURE = 'UPDATE_TEST_ANSWER_FAILURE'

const fetchUpdateTestAnswer = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_TEST_ANSWER_REQUEST, UPDATE_TEST_ANSWER_SUCCESS, UPDATE_TEST_ANSWER_FAILURE ],
    endpoint: `secure/users/update-test-answer`,
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateTestAnswer  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateTestAnswer(body))
};