import {
  PaymentElement,
  PaymentRequestButtonElement
} from '@stripe/react-stripe-js'
import {useState, useEffect} from 'react'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { updateProcessIntent } from '../../../actions/partner-blank-kit-actions'
import base64 from 'base-64' 
import { Base64 as base_64 } from 'js-base64';
import { useDispatch } from "react-redux";
import { Button } from '../../../components';
import { formatNumber } from '../../../helpers/formatNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function PaymentForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [errorMessage, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const [isDisabled, setIsDisabled] = useState(true);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const [clickLoader, setClickLoader] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  let disabled = false;
  let buttonText = 'Submit Payment';
  let success = 'Thank you. Your order has been placed.';

  if (isLoading) {
    buttonText = 'Please wait...'
    disabled = true; 
  } else {
    disabled = false;
  } 
 

  useEffect(() => { 
    if (stripe) { 
      
 
      let totalAmount =  parseFloat((props.totalAmount * 100).toFixed(2));
      // For full documentation of the available paymentRequest options, see:
      // https://stripe.com/docs/stripe.js#the-payment-request-object
      const paymentRequestStripe = stripe.paymentRequest({
        country: 'GB',
        currency: 'gbp',
        total: {
          label: 'Forth',
          amount: totalAmount,
        },
      });

      paymentRequestStripe.on('paymentmethod', function(event) { 
        handlePayment(event); 
      });

      paymentRequestStripe.on('token', ({complete, token, ...data}) => {
        // console.log('Received Stripe token: ', token);
        // console.log('Received customer information: ', data);
      });

      paymentRequestStripe.on('cancel', function() { 
        setClickLoader(false);
      });
      
      paymentRequestStripe.canMakePayment().then((result) => {
        if (result && !result.applePay && !result.googlePay) {
          setCanMakePayment(false);
        } else {
          setCanMakePayment(!!result); 
        }
      }, (err) => {
        console.log(err);
      }); 

      setCanMakePayment(false);
      setPaymentRequest(paymentRequestStripe);
   
   
    }
  }, [stripe]);
 
  

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setIsLoading(true); 
    handlePayment("");
  }
  
 

  const handlePayment = async (event) => {
    var page = this;
    var doNotReview = props.doNotReview;
    if (doNotReview) {
      doNotReview = 1;
    } else {
      doNotReview = 0;
    }
    setIsLoading(true);  

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
 
    let payload = {
      paymentIntent: props.paymentIntent,
      orderId: props.orderId,
      amount: props.totalAmount,
      testId: props.testId,
      customerId: props.customerId,
      hideResults: props.hideResults
    }


    let payloadWindow='payload'
 
  
    setIsLoading(true); 
    const error = await stripe.confirmPayment({
      elements,
      confirmParams: { 
        return_url: `${window.location.origin}/checkout-success/`+payloadWindow,
      },  
      redirect: 'if_required',
    }).then(function(result) { 
      if (result.error) {
        setIsLoading(false);
        setMessage(result.error.message); 
      } else { 
        completePurchase(payload, '');
      } 
    }); 
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    }  

  }

 
  const completePurchase = (payload, event) => {
    //let props = props; 
    setIsLoading(true);
    dispatch(updateProcessIntent(payload))
    .then((response) => {

      if (response.error) {
        //props.cardFormState('failure')
        setIsLoading(false);
        if (event) {
          event.complete('fail');
        }
        props.updatePaymentStatus && props.updatePaymentStatus('fail')
      } else {

        //props.cardFormState('success')
        setIsLoading(false);
        if (event) {
          event.complete('success');
        }
        props.updatePaymentStatus && props.updatePaymentStatus('success')
 
      }
      
      
    })  

  }


  const handlePaymentElementChange = (e) => {
    if (e.complete) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }  
  }
 
  return (
    <div className="checkoutForm stripe"> 
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-12"> 
            <PaymentElement id="payment-element" onChange={(e) => handlePaymentElementChange(e)}/> 
          </div> 
          {isLoading ? ( 
            <div className="col-12">
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : ( 
            <div className="col-12 text-center">
              <Button id="stripeSubmit mt-3" disabled={isDisabled}>Pay £{formatNumber(props.totalAmount)}</Button>
            </div>
          )}
          {errorMessage ? ( 
            <div className="col-12">
              <div className="error text-center pt-3" role="alert">
                <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
              </div>
            </div>
          ) : ( 
            null
          )}
        </div>
      </form>
    </div>
 
  )
}