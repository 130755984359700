import React from 'react'
import RenderIf from '../../RenderIf/RenderIf';
import styles from './Alert.module.scss'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Alert({children, variant = "primary", showIcon = true, icon = faInfoCircle, className}) {
  return (
    children ? (
      <div className={`alert alert-${variant} ${className}`} role="alert">
        <RenderIf isTrue={icon && showIcon}>
          <FontAwesomeIcon icon={icon}/>
        </RenderIf>
        {children}
      </div>
    ) : null
  );
}