import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";  
import _ from 'lodash';
import find from 'lodash/find'
import {PhlebotomyPill, HowToCollect, HowToPhlebotomy, PartnerClinicFinder, Button} from '../../../components/';
import styles from "./PhlebotomySelector.module.scss"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle, faMapMarker } from '@fortawesome/free-solid-svg-icons';

export default function PhlebotomySelector(props) {   
  const [step, setStep] = useState(1);
  const [selectedPhlebotomyOption, setSelectedPhlebotomyOption] = useState(props.phlebotomyOption); 
  const [showScreen, setShowScreen] = useState(1);

  let phlebotomyPrices = props.phlebotomyPrices;
  let phlebotomyOption = selectedPhlebotomyOption;
  let basket = props.basket;
  let guestCheckout = props.guestCheckout;
  if (guestCheckout == 'unset') {
    guestCheckout = false;
  } 
  let testsForPhlebotomy = '';
  let count = 0;
  let doNotShowFinger = 0;
  let doNotShowPhlebotomy = 0;
  let doNotShowPhlebotomyKit = 0;
  let phlebotomyMessage = 0;
  let doNotShowHomePhlebotomy = 0;
  let tests = props.tests;
  let items = [];

  useEffect(() => { 
    setSelectedPhlebotomyOption(props.phlebotomyOption) 
    props.updatePhlebotomyOption && props.updatePhlebotomyOption(props.phlebotomyOption);
  },[props.phlebotomyOption]);

  useEffect(() => {   
    let myModalEl = document.getElementById(props.modalId)
    myModalEl.addEventListener('shown.bs.modal', function (event) {
      setSelectedPhlebotomyOption(props.phlebotomyOption) 
      checkIfOptionsMatch(props.phlebotomyOption, selectedPhlebotomyOption);
    })
     
  },[props.phlebotomyOption, selectedPhlebotomyOption]);

  Object.keys(basket).map(function(key) { 
    find(tests, (test) => {
      if (parseInt(test.testId) === parseInt(basket[key])) {
        items.push(test)
      }  
      if (parseInt(test.testId) === parseInt(basket[key])) {
        if (parseInt(test.testType) == 3) {
          count++;
          testsForPhlebotomy = test.testName + " & ";
        } 
        if (parseInt(test.testType) == 2) {
          count++;
          doNotShowFinger = 1; 
        }

        if (parseInt(test.phlebotomyKitCode) == '') {
          doNotShowPhlebotomy = 1;
          doNotShowHomePhlebotomy = 1;
        }

        if(test.phlebotomyKitCode == 'KT257') {
          count++;
          doNotShowFinger = 1;
          doNotShowPhlebotomyKit = 1;
          phlebotomyMessage = 1;
        }

        if(parseInt(test.doubleKit) == 1) {
          doNotShowPhlebotomy = 1;
          doNotShowHomePhlebotomy = 1;
          doNotShowPhlebotomyKit = 1;
        }

        if(parseInt(test.homePhlebotomyOnly) == 1) {
          doNotShowFinger = 1;
          doNotShowPhlebotomy = 1;
          doNotShowPhlebotomyKit = 1;
          doNotShowHomePhlebotomy = 0; 
        }
      }
    })
  });

  var grouped = _.mapValues(_.groupBy(items, 'testId'));
  
  if (phlebotomyOption == 4) {
    doNotShowFinger = 1;
    doNotShowPhlebotomy = 1;
    doNotShowPhlebotomyKit = 1;
    doNotShowHomePhlebotomy = 1;
    phlebotomyMessage = 0;
  }

  Object.keys(grouped).map(function(key) {
    if (grouped[key].length > 1) {
      doNotShowHomePhlebotomy = 1;
    }
  });
 
  if (Object.keys(basket).length > 1) {
    doNotShowHomePhlebotomy = 1;
  }

  if (guestCheckout) {
    doNotShowHomePhlebotomy = 1;
  }

  testsForPhlebotomy = testsForPhlebotomy.slice(0, -3)

  var phlebotomyError = props.phlebotomyError;

  var errorClass = '';

  if (phlebotomyError) {
    errorClass = 'phlebotomyNoSelectError'
  }

  var fingerClass = false;
  var doNotShowPhlebotomyClass = false;
  var doNotShowPhlebotomyKitClass = false;
  var doNotShowHomePhlebotomyClass = false;

  if (props.onlySellPhlebotomyKits) {
    doNotShowFinger = true;
  }

  if (doNotShowFinger || props.disabledFingerPrick) {
    fingerClass = true;
  }

  if (doNotShowPhlebotomy) {
    doNotShowPhlebotomyClass = true;
  }

  if (doNotShowPhlebotomyKit) {
    doNotShowPhlebotomyKitClass = true;
  }

  if (doNotShowHomePhlebotomy) {
    doNotShowHomePhlebotomyClass = true;
  }

  const phlebotomySelect = (phlebotomyId) => {
    setSelectedPhlebotomyOption(phlebotomyId);
  }


  const onSubmit = (phlebotomyOptionSelected) => {
    if(phlebotomyOptionSelected){
      phlebotomySelect(phlebotomyOptionSelected)
      return props.updatePhlebotomyOption(phlebotomyOptionSelected);
    }

    props.updatePhlebotomyOption(selectedPhlebotomyOption);
  }

  const changeScreen = (e, screenId) => {
    e.preventDefault();
    setShowScreen(screenId);
  }

  const handleBackFromPartnerClinic = (e) => {
    if(showScreen == 4 && step == 2){
      setStep(1)
    }

    if(showScreen == 4 && step == 1){ 
      changeScreen(e, 1); 
      setStep(1)
    }
  }

  const checkIfOptionsMatch = (phlebotomyOption, selectedOption) => { 
    if (phlebotomyOption != selectedOption) {
      setSelectedPhlebotomyOption(phlebotomyOption)
    } 
  }
 

    
  return ( 
    <>
      {showScreen == 1 && ( 
        <div className={styles.contentBlock}>

          <div className="row">
            <div className="col-12 mb-4">
              <button type="button" className={"back-button "+styles.modalBack} data-bs-dismiss="modal" aria-label="Close"><FontAwesomeIcon icon={faChevronLeft} className="me-2"/> Back</button>
            </div> 
            <div className="col-12 text-center">
              <h2 className="mb-4">Collection method</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h4>Do it yourself</h4>
            </div>
            <div className="col-6 text-end"><a href="#" onClick={(e) => changeScreen(e, 2)}>What is this?</a></div>
            <div className="col-12">
              <div className="mb-2">
                <PhlebotomyPill phlebotomySelect={phlebotomySelect} disabled={fingerClass} phlebotomyId={1} phlebotomyPrice={props.phlebotomyPrices[1].phlebotomyPrice} phlebotomyName={props.phlebotomyPrices[1].phlebotomyName} active={selectedPhlebotomyOption == 1 ? true : false}/>
              </div>
              <p>Your kit will include multiple lancets and micro containers to collect your sample from home.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h4>Have a nurse do it</h4>
            </div>
            <div className="col-6 text-end"><a href="#" onClick={(e) => changeScreen(e, 3)}>What is this?</a></div>
            <div className="col-12">
              <div className="mb-2">
                <PhlebotomyPill phlebotomySelect={phlebotomySelect} disabled={doNotShowPhlebotomyKitClass} phlebotomyId={3} phlebotomyPrice={props.phlebotomyPrices[3].phlebotomyPrice} phlebotomyName={props.phlebotomyPrices[3].phlebotomyName} active={selectedPhlebotomyOption == 3 ? true : false}/>
              </div>
              <p>We provide you with a phlebotomy kit so you can organise a professional to draw your blood.</p>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <PhlebotomyPill phlebotomySelect={phlebotomySelect} disabled={doNotShowHomePhlebotomy} phlebotomyId={2} phlebotomyPrice={props.phlebotomyPrices[2].phlebotomyPrice} phlebotomyName={props.phlebotomyPrices[2].phlebotomyName} active={selectedPhlebotomyOption == 2 ? true : false}/>
              </div>
              <p>Book an appointment with one of our nationwide mobile phlebotomists who will come to you.</p>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <PhlebotomyPill phlebotomySelect={phlebotomySelect} disabled={doNotShowPhlebotomyClass} phlebotomyId={5} phlebotomyPrice={props.phlebotomyPrices[5].phlebotomyPrice} phlebotomyName={props.phlebotomyPrices[5].phlebotomyName} active={selectedPhlebotomyOption == 5 ? true : false}/>
              </div>
              <p>
              Book an appointment with one of our nationwide partner clinics where you can have your blood drawn by a healthcare professional.</p>
            </div>

            {!doNotShowPhlebotomyClass && ( 
              <div className="col-12">
                <div className="row">
                  <div className="col-auto pe-0">
                    <FontAwesomeIcon icon={faMapMarker} className={`fa-fw ${styles.pinIcon}`} size='lg' />
                  </div>
                  <div className="col ps-0">
                    <Button variant="link-default" className="d-flex py-0 px-0 ps-2 text-decoration-underline" onClick={(e) => changeScreen(e, 4)}>
                      Find your nearest clinic
                    </Button>
                  </div>
                </div>
              </div> 
            )}
             
            <div className="col-12 mb-5">
              <Button variant="default" data-bs-dismiss="modal" aria-label="Close" className={`w-100 mt-4`} onClick={() => onSubmit()}>Update basket</Button>
            </div>
     
            {phlebotomyError == true && 
              <div className="col-12 phlebotomyError mb-5">
                <FontAwesomeIcon icon={faInfoCircle} />Please select which type of collection kit you require for your bespoke test.
              </div>
            }
          </div>
        </div> 
      )}
      {showScreen == 2 && ( 
        <div className={styles.contentBlock}>
          <div className="col-12 mb-4">
            <button type="button" className={"back-button "+styles.modalBack} onClick={(e) => changeScreen(e, 1)}><FontAwesomeIcon icon={faChevronLeft} className="me-2"/> Back</button>
          </div> 
          <HowToCollect/>  
        </div>
      )}
      {showScreen == 3 && ( 
        <div className={styles.contentBlock}>
          <div className="col-12 mb-4">
            <button type="button" className={"back-button "+styles.modalBack} onClick={(e) => changeScreen(e, 1)}><FontAwesomeIcon icon={faChevronLeft} className="me-2"/> Back</button>
          </div> 
          <HowToPhlebotomy phlebotomyPrices={props.phlebotomyPrices}/>  
        </div>
      )}
      {showScreen == 4 && ( 
        <div className={styles.contentBlock}>
          <div className="col-12 mb-4">
            <button type="button" className={"back-button "+styles.modalBack} onClick={(e) => handleBackFromPartnerClinic(e)}><FontAwesomeIcon icon={faChevronLeft} className="me-2"/> Back</button>
          </div> 
          <PartnerClinicFinder step={step} setStep={setStep} partnerClinics={props.partnerClinics} phlebotomyPrice={props.phlebotomyPrices[5].phlebotomyPrice} changeScreen={changeScreen} updateToPhlebotomyClinic={(phlebotomyOption) => onSubmit(phlebotomyOption)}/>  
        </div>
      )}
    </>
  )
}



 