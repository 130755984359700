import React, { useEffect, useState } from 'react'
import md5 from 'md5';
import base64 from 'base-64'
import StripeCheckout from 'react-stripe-checkout';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import config from '../../../config'
import {Alert, BasketItems, Button, CartSummary, CheckoutProgressBar, YourOrderWrapper, CheckoutForm, StripeFormPlaceholder} from '../../';
import calculateBasket from '../../../helpers/calculateBasket';
import runGA from '../../../helpers/runGA';
import RenderIf from '../../RenderIf/RenderIf';
import styles from './CheckoutPayment.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import isEmpty from 'lodash/isEmpty';
const stripePromise = loadStripe(config.STRIPE_KEY);

const isBiomarkerTracking = global.config.websiteId != 1 && global.config.websiteId != 2


export default function CheckoutPayment(props) {
  const [subtotal, setSubtotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState("")
  const [credit, setCredit] = useState("")
  const [testPrice, setTestPrice] = useState();
  const [discountedCredit, setDiscountedCredit] = useState("")
  const [orderId, setOrderId] = useState(0);
  const [orderReference, setOrderReference] = useState(0);
  const [subscriptionOrder, setSubscriptionOrder] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [cardLoading, setCardLoading] = useState(true);
  const [termsError, setTermsError] = useState(false);
  const [disableFreeButton, setDisableFreeButton] = useState(false);
  const [subquentialAmounts, setSubquentialAmounts] = useState(0)
  const partner = props.partner;
  const cartItems = props.cart[0];
  const voucher = JSON.parse(localStorage.getItem('voucher'));
  const user = props.user;
 
  useEffect(() => {  
    getValues(); 
  },[props]);

  let websiteId = 1;
  let partnerCode = '';
  let partnerPortalId = localStorage.getItem('partnerPortalId')
  if (Object.keys(partner).length > 0) {
    partnerPortalId = partner[0].partnerId;
  } 

  if ((global.config.websiteId == 3 || partnerPortalId != 0) && Object.keys(partner).length > 0) { 
    partnerCode = partner[0].requestCode;  
  }  

  if (Object.keys(props.cart).length > 0) {
    let basketArray = props.cart[0].basketArray;
  }

  if (global.config.websiteId == 1 || global.config.websiteId == 3) {
    websiteId = 1;
  } else {
    websiteId = 2;
  } 

  let voucherId;
  if (voucher) {
    voucherId = voucher.iD;
  }

  let voucherAppliesToSubscription = "false";

  if (voucher) {
    voucherAppliesToSubscription = voucher.voucherAppliesToSubscription;
  }

  let deliveryImage = '';
  let websiteUrl = 'https://sportsbloodtests.co.uk';
  if (global.config.websiteId == 1 || global.config.websiteId == 3) { 
    deliveryImage = '/img/lifeBasketDelivery.jpg';
    websiteUrl = 'https://forthwithlife.co.uk';
  } else {
    deliveryImage = '/img/edgeBasketDelivery.jpg';
    websiteUrl = 'https://sportsbloodtests.co.uk';
  }

  let orderCookieId = localStorage.getItem('orderCookieId');

  let freeOrderEncryptedUrl = md5(config.SALT3+voucherId+user.customerCookieId+orderCookieId+config.SALT5);
  let terms;
  let privacy;
  if (global.config.websiteId == 1 || global.config.websiteId == 3) {
    terms = 'https://www.forthwithlife.co.uk/terms-of-service/';
    privacy = 'https://www.forthwithlife.co.uk/privacy-policy/';
  } else {
    terms = 'https://www.sportsbloodtests.co.uk/terms-of-service/';
    privacy = 'https://www.sportsbloodtests.co.uk/privacy-policy/';
  }

    
  let grantPartnerPermission = 1;
  /*
  if (props.grantPartnerPermission) {
    grantPartnerPermission = 1
  } */


  let guestCheckout = props.guestCheckout;
  let checkoutType = 'Standard'

  if (props.guestCheckout == 'unset') {
    guestCheckout = false; 
  }

  if (props.guestCheckout && props.guestCheckout != 'unset') {
    checkoutType = 'Guest'
  }

  let freeCheckoutUserId = props.user.iD;


  if(guestCheckout) {
    if(Object.keys(partner).length > 0 || partnerPortalId) {
      freeCheckoutUserId = props.guestCustomerId;
    }
  }

  let gtmArray = [];



  let partnerDiscount = false;

  if (Object.keys(partner).length > 0 && parseInt(partner[0].partnerDiscount)) {
    partnerDiscount = true; 
  }
  
  let phlebotomyType = 'Finger-prick'; 
  let bespokeTest = false;
 
 
  if (props.guestCheckout && props.guestCheckout != 'unset') {
    guestCheckout = true;
  }

  let receiptEmailAddress

  if (props.checkoutFromPartner){
    receiptEmailAddress = props.clientReceipt == true ? props.receiptEmailAddress : props.partnerEmail 
  } else {
    receiptEmailAddress = props.receiptEmailAddress
  }
 
  const getValues = () => {
    let cartProps = {
      "user": props.user,
      "cart": props.cart[0],
      "tests": props.basketTestItems,
      "basket": props.cart[0].basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "nextDayDeliveryPrice": props.nextDayDeliveryPrice,
      "step": props.step
    } 
    
    let checkoutTotal = calculateBasket(cartProps); 
    setSubtotal(checkoutTotal.subtotal);
    setTestPrice(checkoutTotal.testPrice);
    setSubquentialAmounts(checkoutTotal.subquentialAmounts)
  }

  const toggleAgreement = () => {  
    setAgreeTerms(!agreeTerms); 
    setTermsError(false)
  }
 
  useEffect(() => {  
    const partner = props.partner
    const user = props.user;
    let orderCookieId = localStorage.getItem('orderCookieId');
    let partnerId = 0;
    if (Object.keys(partner).length > 0) {
      partnerId = props.partner?.[0]?.partnerId || 0;
    }
    //setCardLoading(true)
    props.loadGetCart(orderCookieId, 0, partnerId, props.checkoutFromPartner).then(async function(response) {

      let cart = response.response.entities.cart[0].basketArray;
      // TODO: remove this after fixing the bug where the cart is not returned from the server
      if(isEmpty(cart)) {
        response = await props.loadGetCart(orderCookieId, 0, partnerId, props.checkoutFromPartner)
        cart = response.response.entities.cart[0].basketArray
      }

      let subscriptionOrder = false;
      let orderReference = ''; 
      Object.keys(cart).map(function(key) { 
        if ((cart[key][5] > 0) && (cart[key][6] == 0)) {
          subscriptionOrder = true; 
        } 
      });

      if (props.subscriptionOption == 0) {
        subscriptionOrder = false; 
      }
 
      let finalTotalAmount = parseFloat(response.response.entities.cart[0].totalAmount).toFixed(2);
      let credit = response.response.entities.cart[0].credit
      let discountedCredit = response.response.entities.cart[0].discountedCredit
      let totalSaving = response.response.entities.cart[0].totalSaving
      let userId = user.iD;
      let activationKit = false;

      if (props.guestCheckout && props.guestCheckout != 'unset') {
        userId = props.guestCustomerId;
        activationKit = true;
      } 



      setCredit(credit);
      setDiscountedCredit(discountedCredit);
      props.setCart(response.response.entities.cart)
  
      props.loadUpdateBasketReference(userId, subscriptionOrder, orderCookieId)
      .then(function(response) {
        if (!response.error) { 
          
          setOrderId(response.response.orderId)
          setOrderReference(response.response.orderReference)
          setSubscriptionOrder(subscriptionOrder)
          setTotalAmount(finalTotalAmount)
          setCredit(credit)
          setDiscountedCredit(discountedCredit)
      

          let websiteId = 1;
          if (global.config.websiteId == 2) { 
            websiteId = 2;
          } 
          let partnerCode = ''
          let partnerPortalId = localStorage.getItem('partnerPortalId');
          if ((global.config.websiteId == 3 || partnerPortalId != 0) && Object.keys(partner).length > 0) { 
            partnerCode = partner[0].requestCode;  
          }   
          let grantPartnerPermission = 1;
          /*
          if (props.grantPartnerPermission) {
            grantPartnerPermission = 1
          } */
          const voucher = JSON.parse(localStorage.getItem('voucher'));
          let voucherId;
          if (voucher) {
            voucherId = voucher.iD;
          }
          //let orderCookieId = localStorage.getItem('orderCookieId');

          let receiptEmailAddress = props.receiptEmailAddress 

/*           if (props.checkoutFromPartner){
            receiptEmailAddress = props.clientReceipt == true ? props.receiptEmailAddress : props.partnerEmail 
          } else {
            receiptEmailAddress = props.receiptEmailAddress
          } */


          let payload = {
            userId: userId ? userId : props.userId,
            userIp: '',
            websiteId: websiteId,
            amount: finalTotalAmount * 100,
            id:'',
            orderReference: response.response.orderReference,
            orderId: response.response.orderId,
            stripeCustomerId:'', 
            userEmail: props.user.email ? props.user.email : props.customerEmailAddress,
            totalAmount: finalTotalAmount,
            totalSaving: totalSaving,
            subscriptionOrder: subscriptionOrder.toString(),
            activationKit: activationKit,
            partnerId: partnerPortalId,
            partnerCode: partnerCode,
            overidePayPalProcess: '',
            voucherId: voucherId,
            f: '',
            freeOrderEncryptedUrl: '',
            stripeToken: '',
            orderCookieId: orderCookieId,
            doNotReview: props.doNotReview,
            credit: credit * 100,
            discountedCredit: discountedCredit,
            receiptEmailAddress: global.config.websiteId == 3 ? receiptEmailAddress : "",
            grantPartnerPermission: grantPartnerPermission,
            checkoutFromPartner: props.checkoutFromPartner,
            clientEmailsCheck: props.clientEmailsCheck,
            clientReciept: props.clientReceipt,
            clientReferenceId: window?.Rewardful?.referral ?? ''
          }

       
          if (finalTotalAmount > 0.00 || subscriptionOrder == true) {
            props.updatePaymentIntent(payload)
            .then((response) => { 
              props.setClientSecret(response.response.clientSecret)
              props.setPaymentIntent(response.response.id) 
              props.setStripeCustomerId(response.response.stripeCustomerId) 
              setCardLoading(false) 
            });
          } else {
            setCardLoading(false) 
          }
        }  
      });

    }); 
  }, [])
  
  const cardFormState = (data) => {
    if (data == "success") { 
      props.setPaymentSuccess(true);
      props.loadUser("me");
      localStorage.removeItem("referrer");
      localStorage.removeItem("orderCookieId");
      localStorage.removeItem("basket");
      localStorage.removeItem("voucher");
      localStorage.removeItem("voucherStatus");
      localStorage.removeItem("FromPartnerPortal");
      localStorage.removeItem("partnerPortalId");
      props.removeAllFromCart();
      localStorage.removeItem("token"); 
      props.goToStep(5, false, false, true);
      localStorage.removeItem("token");
      runGA("/checkout/payment-success");
      if(window.parent) {
        window.parent.postMessage('checkoutSuccess', '*');
      }
    } else { 
      props.loadUser("me");
      props.setPaymentSuccess(false);
      props.goToStep(5);
      localStorage.removeItem("token");
    }
  }

  const handleCheckboxChange = (userField) => {
    let customerOptIn = props.customerDetails.customerOptIn == 0 ? false : true;
    let doNotReview = props.customerDetails.doNotReview == 0 ? false : true;

    if (userField == 'customerOptIn') {
      props.setCustomerDetails({
        ...props.customerDetails,  
        customerOptIn: !customerOptIn
      })
      customerOptIn = !customerOptIn
    }
    
    if (userField == 'doNotReview') {
      props.setCustomerDetails({
        ...props.customerDetails,  
        doNotReview: !doNotReview
      })
      doNotReview = !doNotReview
    } 

    let updatePayload;
      if (guestCheckout) {
        updatePayload = {
          customerFirstName: props.customerDetails.customerFirstName,
          customerLastName: props.customerDetails.customerLastName,
          customerEmailAddress: props.customerDetails.customerEmailAddress,
          customerHomeTelephone: props.customerDetails.customerHomeTelephone,
          customerDOBD: props.customerDetails.customerDOBD,
          customerDOBM: props.customerDetails.customerDOBM,
          customerDOBY: props.customerDetails.customerDOBY,
          customerSex: props.customerDetails.customerSex,
          customerEthnicity: 0,
          customerMenopausal: props.customerDetails.customerMenopausal,
          customerWeight: 0,
          customerWeightST: 0,
          customerWeightIB: 0,
          customerWeightType: 1,
          customerHeight: 0,
          customerHeightFT: 0,
          customerHeightIN: 0,
          customerHeightType: 1,
          customerAddress1: props.customerDetails.customerAddress1,
          customerAddress2: props.customerDetails.customerAddress2,
          customerAddress3: props.customerDetails.customerAddress3,
          customerAddress4: "",
          customerCity: props.customerDetails.customerCity,
          customerCountry: props.customerDetails.customerCountry,
          customerPostcode: props.customerDetails.customerPostcode,
          customerOptIn: customerOptIn,
          customerMainGoal: 0,
          customerExercise: 0,
          customerPrimarySport: 0,
          customerOftenExercise: 0,
          customerTraining: "",
          customerHealthyDiet: 0,
          customerDiet: 0,
          customerAlcoholUnitsId: 0,
          customerSupliments: 0,
          customerSuplimentsDetails: 0,
          customerSuffer: 0,
          customerSufferDetails: 0,
          customerRegularPeriods: props.customerDetails.customerRegularPeriods,
          customerContraception: props.customerDetails.customerContraception,
          customerHRT: props.customerDetails.customerHRT,
          customerAverageCycleLength: props.customerDetails.customerAverageCycleLength,
          guestCustomerId: props.customerDetails.guestCustomerId,
          guestCustomer: true,
          doNotReview: doNotReview,
          overideAndAllowFHM: props.user.overideAndAllowFHM,
          grantPartnerPermission: 1,
          partnerPortalId
        };
      } else {
        updatePayload = {
          customerFirstName: props.customerDetails.customerFirstName,
          customerLastName: props.customerDetails.customerLastName,
          customerEmailAddress: props.customerDetails.customerEmailAddress,
          customerHomeTelephone: props.customerDetails.customerHomeTelephone,
          customerDOBD: props.customerDetails.customerDOBD,
          customerDOBM: props.customerDetails.customerDOBM,
          customerDOBY: props.customerDetails.customerDOBY,
          customerSex: props.customerDetails.customerSex,
          customerEthnicity: props.customerDetails.customerEthnicity,
          customerMenopausal: props.customerDetails.customerMenopausal,
          customerWeight: props.user.customerWeightKG ? props.user.customerWeightKG : 0,
          customerWeightST: props.user.customerWeightST ? props.user.customerWeightST : 0,
          customerWeightIB: props.user.customerWeightIB ? props.user.customerWeightIB : 0,
          customerWeightType: props.user.customerWeightType
            ? props.user.customerWeightType
            : 1,
          customerHeight: props.user.customerHeightM ? props.user.customerHeightM : 0,
          customerHeightFT: props.user.customerHeightFT ? props.user.customerHeightFT : 0,
          customerHeightIN: props.user.customerHeightIN ? props.user.customerHeightIN : 0,
          customerHeightType: props.user.customerHeightType
            ? props.user.customerHeightType
            : 1,
          customerAddress1: props.customerDetails.customerAddress1,
          customerAddress2: props.customerDetails.customerAddress2,
          customerAddress3: props.customerDetails.customerAddress3,
          customerAddress4: props.user.customerAddress4 ? props.user.customerAddress4 : "",
          customerCity: props.customerDetails.customerCity,
          customerCountry: props.customerDetails.customerCountry,
          customerPostcode: props.customerDetails.customerPostcode,
          customerOptIn: customerOptIn,
          customerMainGoal: props.customerDetails.customerMainGoal
            ? props.customerDetails.customerMainGoal
            : 0,
          customerExercise: props.user.customerExerciseId
            ? props.user.customerExerciseId
            : 0,
          customerPrimarySport: props.user.customerPrimarySport
            ? props.user.customerPrimarySport
            : 0,
          customerOftenExercise: props.user.customerOftenExercise
            ? props.user.customerOftenExercise
            : 0,
          customerTraining: props.user.customerTraining ? props.user.customerTraining : "",
          customerHealthyDiet: props.user.healthyDietTypeId
            ? props.user.healthyDietTypeId
            : 0,
          customerDiet: props.user.dietTypeId ? props.user.dietTypeId : 0,
          customerAlcoholUnitsId: props.user.customerAlcoholUnitsId
            ? props.user.customerAlcoholUnitsId
            : 0,
          customerSupliments: props.user.customerSuplimentsId
            ? props.user.customerSuplimentsId
            : 0,
          customerSuplimentsDetails: props.user.customerSuplimentsDetails
            ? props.user.customerSuplimentsDetails
            : 0,
          customerSuffer: props.user.customerSufferId ? props.user.customerSufferId : 0,
          customerSufferDetails: props.user.customerSufferDetails
            ? props.user.customerSufferDetails
            : 0,
          customerRegularPeriods: props.customerDetails.customerRegularPeriods || 0,
          customerAverageCycleLength: props.customerDetails.customerAverageCycleLength || "",
          customerContraception: props.customerDetails.customerContraception || 0,
          customerContraceptionTypeOther:
            props.user.customerContraceptionTypeOther || "",
          customerIsPregnant: props.user.customerIsPregnant || 0,
          customerIsBreastfeeding: props.user.customerIsBreastfeeding || 0,
          customerHRT: props.customerDetails.customerHRT || 0,
          customerAdditionalMedicalInfo:
            props.user.customerAdditionalMedicalInfo || "",
          guestCustomerId: props.user.iD,
          orderCookieId: localStorage.getItem("orderCookieId"),
          doNotReview: doNotReview,
          overideAndAllowFHM: props.user.overideAndAllowFHM,
          checkoutFromPartner: props.customerDetails.checkoutFromPartner,
          customerId: props.customerDetails.checkoutFromPartner == 1 ? props.customerDetails.userId : undefined,
          grantPartnerPermission: 1,
          partnerPortalId
        };
      } 
      
      if(props.checkoutFromPartner == 1){
        updatePayload = {
          ...updatePayload,
          customerId: localStorage.getItem("customerId"),
          checkoutFromPartner: 1
        }
      }

    props.updateUserProfile(updatePayload).then((response) => {
  
      let partnerId;
      if (Object.keys(partner).length > 0) {
        partnerId = partner[0].partnerId;
      } 
      
      props.loadUser('me').then((response) => { 
        // props.reloadUserData();
 
        if (!response.error && response.response.partnerId != 0){
          props.loadPartnerDetailsUnsecure(response.response.partnerId).then(()=>{     
            if(isBiomarkerTracking) {
              localStorage.setItem('partnerPortalId', response.response.partnerId);  
            } else {
              localStorage.setItem('partnerPortalId', 0)
            }      
          })
        }  
      });

    
    });
     
  }
  
  const completeFreeOrder = (id, userId, userIp, websiteId, amount, orderReference, orderId, userEmail, totalAmount, totalSaving, subscriptionOrder, activationKit, partnerId, partnerCode, overidePayPalProcess, voucherId, f, freeOrderEncryptedUrl, orderCookieId, credit, discountedCredit, grantPartnerPermission) => {
    setTermsError(false)
    if(!agreeTerms){
      setTermsError(true)
      setTimeout(()=> {
        document.querySelector(".alert.alert-danger.basketAlert")?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      },200)
      return
    }

    setDisableFreeButton(true)
 
    let doNotReview = props.customerDetails.doNotReview;
    if (doNotReview == 1 || doNotReview == "1") {
      doNotReview = true;
    } else {
      doNotReview = false;
    }
 
    let cart = props.cart[0].basketArray;
    let newCart = [];
    Object.keys(cart).map(function(key) {
      newCart.push(cart[key][0] + ":0");
    });

    let newUrl = base64.encode(newCart.toString());
    let items = props.basketTestItems;
    let itemArray = [];
    Object.keys(items).map(function(key) {
      itemArray.push(items[key]);
    });
 

    let receiptEmailAddress = props.receiptEmailAddress

/*     if (props.checkoutFromPartner){
      receiptEmailAddress = props.clientReceipt == true ? props.receiptEmailAddress : props.partnerEmail 
    } else {
      receiptEmailAddress = props.receiptEmailAddress
    } */

    let payload = {
      userId: userId,
      userIp: userIp,
      websiteId: websiteId,
      amount: amount,
      id: id,
      orderReference: orderReference,
      orderId: orderId,
      stripeCustomerId: "",
      userEmail: userEmail,
      totalAmount: totalAmount,
      totalSaving: totalSaving,
      subscriptionOrder: subscriptionOrder,
      activationKit: activationKit,
      partnerId: partnerId,
      partnerCode: partnerCode,
      overidePayPalProcess: overidePayPalProcess,
      voucherId: voucherId,
      f: f,
      freeOrderEncryptedUrl: freeOrderEncryptedUrl,
      stripeToken: "",
      orderCookieId: orderCookieId,
      credit: credit,
      discountedCredit: -Math.abs(discountedCredit),
      doNotReview: doNotReview,
      grantPartnerPermission: 1,
      checkoutFromPartner: props.checkoutFromPartner,
      clientEmailsCheck: props.clientEmailsCheck,
      clientReciept: props.clientReceipt,
      receiptEmailAddress: global.config.websiteId == 3 ? receiptEmailAddress : "",
      clientReferenceId: window?.Rewardful?.referral ?? ''
    };
 
    props.updatePaymentIntent(payload).then((response) => {
      if (!response.error) { 
        localStorage.removeItem("referrer");
        localStorage.removeItem("orderCookieId");
        localStorage.removeItem("basket");
        localStorage.removeItem("voucher");
        localStorage.removeItem("voucherStatus");
        localStorage.removeItem("FromPartnerPortal");
        localStorage.removeItem("partnerPortalId");
        props.loadUser("me");
        localStorage.removeItem("token");
        props.setPaymentSuccess(true);
        if(window.parent) {
          window.parent.postMessage('checkoutSuccess', '*');
        }
        setDisableFreeButton(false);
        props.goToStep(5, false, false, true);
      } else {
        props.loadUser("me");
        props.setPaymentSuccess(false);
        setDisableFreeButton(false);
        props.goToStep(5);
      }
    });
 
  }

  return (
    <>
      <div className="row d-block d-md-none pt-0 pt-md-5 g-0 w-100">
        <div className="col-12">
          <YourOrderWrapper subtotal={subtotal} totalAmount={parseFloat(props.cart[0].totalAmount)} partner={props.partner} checkoutFromPartner={props.checkoutFromPartner} step={props.step}>
            <BasketItems
              cart={props.cart}
              basketTestItems={props.basketTestItems}
              phlebotomyOption={props.phlebotomyOption}
              getVoucher={props.getVoucher}
              globalPlusTests={props.globalPlusTests}
              setPlusTests={props.setPlusTests}
              handleQuantityChange={props.handleQuantityChange}
              subscriptionInfo={props.subscriptionInfo}
              subscriptionOption={props.subscriptionOption}
              step={props.step} 
              partner={props.partner} 
              voucher={props.voucher} 
              handleSubscriptionChange={props.handleSubscriptionChange}
              globalHCTests={props.globalHCTests}
              setGlobalHCTests={props.setGlobalHCTests}
            />
            <CartSummary
              onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
              deliveryInfo={props.deliveryInfo}
              customerDetails={props.customerDetails}
              phlebotomyPrices={props.phlebotomyPrices}
              voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
              subscriptionOption={props.subscriptionOption}
              step={props.step}
              globalPlusTests={props.globalPlusTests}
              deliveryOption={props.deliveryOption}
              guestCheckout={props.guestCheckout}
              subscriptionInfo={props.subscriptionInfo}
              cart={props.cart[0]}
              basketTestItems={props.basketTestItems}
              basketArray={props.cart[0].basketArray}
              phlebotomyOption={props.phlebotomyOption}
              voucher={props.voucher}
              user={props.user}
              partner={props.partner}
              nextDayDeliveryPrice={props.nextDayDeliveryPrice}
              partnerClinics={props.partnerClinics}
              basket={props.basket}
              tests={props.basketTestItems}
              updatePhlebotomyOption={props.updatePhlebotomyOption}
              phlebotomyError={props.phlebotomyError}
              updateDeliveryOption={props.updateDeliveryOption} 
              globalHCTests={props.globalHCTests}
            /> 
          </YourOrderWrapper>
             
        </div>
      </div>

      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-md-6">
            <CheckoutProgressBar showQuestionAdditionalStep={props.foundEndoTest || props.foundTrueCheckTest} goToStep={props.goToStep}  step={props.step} checkoutFromPartner={props.checkoutFromPartner} skipStepOne={props.skipStepOne} guestCheckout={props.guestCheckout}/>
             
            <div className="row takePayment">
              <div className="pt-5 col-12 text-center">
                <h2>Secure payment</h2>
              </div>
              
              <RenderIf isTrue={!cardLoading && orderReference}>
                <div className="col-12 pb-4">
   
                  {(totalAmount > 0 && testPrice > 0) || ((voucherAppliesToSubscription == "false" || voucherAppliesToSubscription == false) && (subscriptionOrder && subquentialAmounts > 0)) ? ( 
                    <>
                    {subscriptionOrder.toString() == "true" ? (
                  
                      <>
                      <Elements stripe={stripePromise} options={{ clientSecret: props.clientSecret }}>
                        <CheckoutForm
                          setTermsError={setTermsError}
                          agreeTerms={agreeTerms} 
                          setPaymentType={props.setPaymentType}
                          paymentIntent={props.paymentIntent} 
                          clientSecret={props.clientSecret}
                          stripeCustomerId={props.stripeCustomerId}
                          mainCheckout = {true} 
                          userId = {props.user.iD}
                          userIp = ''
                          websiteId = {websiteId}
                          amount = {totalAmount * 100}
                          orderReference = {orderReference}
                          orderId = {orderId}
                          userEmail = {props.user.email}
                          totalAmount = {totalAmount}
                          totalSaving = {props.cart[0].totalSaving}
                          subscriptionOrder = {subscriptionOrder.toString()}
                          activationKit = {false}
                          partnerId = {partnerPortalId}
                          partnerCode = {partnerCode}
                          grantPartnerPermission = {1}
                          overidePayPalProcess = ''
                          voucherId = {props.voucher.iD}
                          f = ''
                          freeOrderEncryptedUrl = ''
                          requestType={'buySubscription'}
                          orderCookieId={orderCookieId}
                          doNotReview={props.customerDetails.doNotReview}
                          credit={credit * 100}
                          discountedCredit={discountedCredit}
                          cardFormState={cardFormState}
                          receiptEmailAddress = {props.receiptEmailAddress}
                          cart={props.cart}
                          partner={props.partner}
                          phlebotomyOption={props.phlebotomyOption}
                          basketTestItems={props.basketTestItems}
                          voucher={props.voucher}
                          customerEmailAddress={props.customerDetails.customerEmailAddress}
                          customerFirstName={props.customerDetails.customerFirstName}
                          customerLastName ={props.customerDetails.customerLastName} 
                          checkoutFromPartner={props.checkoutFromPartner}
                          clientEmailsCheck={props.clientEmailsCheck}
                          clientReceipt={props.clientReceipt}
                          />
                      </Elements> 
                      </>

                    ) : (
                      
                      <React.Fragment>
                        {guestCheckout && guestCheckout != 'unset' ? (
                          <>
                            <Elements stripe={stripePromise} options={{ clientSecret: props.clientSecret }}>
                              <CheckoutForm 
                                setTermsError={setTermsError}
                                agreeTerms={agreeTerms} 
                                setPaymentType={props.setPaymentType}
                                paymentIntent={props.paymentIntent} 
                                clientSecret={props.clientSecret}
                                stripeCustomerId={props.stripeCustomerId}
                                mainCheckout = {true} 
                                userId = {props.guestCustomerId}
                                userIp = ''
                                websiteId = {websiteId}
                                amount = {totalAmount * 100}
                                orderReference = {orderReference}
                                orderId = {orderId}
                                userEmail = {props.customerDetails.customerEmailAddress}
                                totalAmount = {totalAmount}
                                totalSaving = {props.cart[0].totalSaving}
                                subscriptionOrder = {subscriptionOrder.toString()}
                                activationKit = {true}
                                partnerId = {partnerPortalId}
                                partnerCode = {partnerCode}
                                grantPartnerPermission = {1}
                                overidePayPalProcess = ''
                                voucherId = {props.voucher.iD}
                                f = ''
                                freeOrderEncryptedUrl = ''
                                requestType={'buySingleProduct'}
                                orderCookieId={orderCookieId}
                                doNotReview={props.customerDetails.doNotReview}
                                credit={credit * 100}
                                discountedCredit={discountedCredit}
                                cardFormState={cardFormState}
                                receiptEmailAddress = {props.receiptEmailAddress}
                                cart={props.cart}
                                partner={props.partner}
                                phlebotomyOption={props.phlebotomyOption}
                                basketTestItems={props.basketTestItems}
                                voucher={props.voucher}
                                customerEmailAddress={props.customerDetails.customerEmailAddress}
                                customerFirstName={props.customerDetails.customerFirstName}
                                customerLastName ={props.customerDetails.customerLastName} 
                                checkoutFromPartner={props.checkoutFromPartner}
                                clientEmailsCheck={props.clientEmailsCheck}
                                clientReceipt={props.clientReceipt} 
                                />
                            </Elements> 
                          </> 
                        ) : (
                          <>
                            <Elements stripe={stripePromise} options={{ clientSecret: props.clientSecret }}>
                              <CheckoutForm 
                                setTermsError={setTermsError}
                                agreeTerms={agreeTerms} 
                                setPaymentType={props.setPaymentType}
                                paymentIntent={props.paymentIntent} 
                                clientSecret={props.clientSecret}
                                stripeCustomerId={props.stripeCustomerId}
                                mainCheckout = {true} 
                                userId = {props.user.iD}
                                userIp = ''
                                websiteId = {websiteId}
                                amount = {totalAmount * 100}
                                orderReference = {orderReference}
                                orderId = {orderId}
                                userEmail = {props.customerEmailAddress}
                                totalAmount = {totalAmount}
                                totalSaving = {props.cart[0].totalSaving}
                                subscriptionOrder = {subscriptionOrder.toString()}
                                activationKit = {false}
                                partnerId = {partnerPortalId}
                                partnerCode = {partnerCode}
                                grantPartnerPermission = {1}
                                overidePayPalProcess = ''
                                voucherId = {props.voucher.iD}
                                f = ''
                                freeOrderEncryptedUrl = ''
                                requestType={'buySingleProduct'}
                                orderCookieId={orderCookieId}
                                doNotReview={props.customerDetails.doNotReview}
                                credit={credit * 100}
                                discountedCredit={discountedCredit}
                                cardFormState={cardFormState}
                                receiptEmailAddress = {props.receiptEmailAddress}
                                cart={props.cart}
                                partner={props.partner}
                                phlebotomyOption={props.phlebotomyOption}
                                basketTestItems={props.basketTestItems}
                                voucher={props.voucher}
                                customerEmailAddress={props.customerDetails.customerEmailAddress}
                                customerFirstName={props.customerDetails.customerFirstName}
                                customerLastName ={props.customerDetails.customerLastName} 
                                checkoutFromPartner={props.checkoutFromPartner}
                                clientEmailsCheck={props.clientEmailsCheck}
                                clientReceipt={props.clientReceipt}
                                />
                            </Elements> 
                          </> 
                        )}
                      </React.Fragment>
                        
                    )}
                    </>

                  ) : parseFloat(testPrice) == 0.00 && parseFloat(totalAmount) == 0.00 ? (
                    <div className="checkoutForm stripe">
                      <div className="row my-4">  
                        <div className="col-12 col-lg-9 m-auto text-center mb-4">
                          <h2 className="text-uppercase">No Payment Required</h2>
           
                          <p>No payment is required, please complete your order by pressing the button below</p>
                          
                          <Button id="cardButton" onClick={() => { completeFreeOrder('free', freeCheckoutUserId, '', websiteId, totalAmount * 100, orderReference, orderId, props.customerDetails.customerEmailAddress, totalAmount, props.cart[0].totalSaving, subscriptionOrder.toString(), guestCheckout, partnerPortalId, partnerCode, '', props.voucher.iD, 'f', freeOrderEncryptedUrl, orderCookieId, credit * 100, discountedCredit * 100, grantPartnerPermission ) }}>Complete order</Button>
                        </div>

                    
                      </div>

                     

                    </div>
                  ) : ( 

                    <div className="col-12 my-5"> 
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                     
                    
                  )}
              
                </div>
              </RenderIf>

              <RenderIf isTrue={cardLoading}>
                <div className="col-12 my-5">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </RenderIf>

              <div className="col-12">
                <div className={agreeTerms ? styles.styledCheckbox+' '+styles.active : styles.styledCheckbox} onClick={() => {  toggleAgreement() }}> 
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={"me-3 "+styles.checkBoxCircle}>
                        {agreeTerms ? <FontAwesomeIcon icon={faCheck}/> : ''}
                      </div>
                      <div className="d-flex">
                        <p className="m-0">I have read and accept your <a onClick={(e) => e.stopPropagation() } target="_blank" href={terms}>terms of service</a> and <a onClick={(e) => e.stopPropagation() } target="_blank" href={privacy}>privacy policy</a>.</p> 
                      </div>
                    </div> 
                  </div>  
                </div>  
              </div>
              
              {termsError && (  
                <div className="col-md-12 mt-3">
                  <div className="alert alert-danger basketAlert" role="alert">Please accept the terms and conditions to proceed with payment</div> 
                </div> 
              )}

              <div className="row justify-content-center mt-4">
                <h5 className="mb-4 fw-normal text-center">
                  <img className={`${styles.shieldIcon} me-2`} src="/img/certified.png" alt="Certified icon" style={{filter: "invert(1)"}}/> Certified for quality & security
                </h5>
                <div className="row justify-content-center">
                  <img className={`${styles.isoIcon} mb-4`} src="/img/ISO15189.png" alt="ISO Certified" />
                  <img className={`${styles.isoIcon} mb-4`} src="/img/iso13485.png" alt="ISO Certified" />
                  <img className={`${styles.isoIcon} mb-4`} src="/img/iso27001.png" alt="ISO Certified" />
                  <img className={`${styles.isoIcon} mb-4`} src="/img/iso9001.png" alt="ISO Certified" />
                  <img className={`${styles.certifiedIcon} mb-4`} src="/img/MDR-CE-White.png" alt="MDR and CE Certified " style={{filter: "invert(1)"}}/>

                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 text-center">
                  <img className={`${styles.trustpilot}`} src="/img/trustpilot.png" alt="Trustpilot score" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 d-none d-md-block"> 
            <YourOrderWrapper>
              <BasketItems
                cart={props.cart}
                basketTestItems={props.basketTestItems}
                phlebotomyOption={props.phlebotomyOption}
                getVoucher={props.getVoucher}
                globalPlusTests={props.globalPlusTests}
                setPlusTests={props.setPlusTests}
                handleQuantityChange={props.handleQuantityChange}
                subscriptionInfo={props.subscriptionInfo}
                subscriptionOption={props.subscriptionOption}
                step={props.step} 
                partner={props.partner} 
                voucher={props.voucher} 
                handleSubscriptionChange={props.handleSubscriptionChange}
                globalHCTests={props.globalHCTests}
                setGlobalHCTests={props.setGlobalHCTests}
              />
              <CartSummary 
                onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
                deliveryInfo={props.deliveryInfo}
                customerDetails={props.customerDetails}
                phlebotomyPrices={props.phlebotomyPrices}
                voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
                subscriptionOption={props.subscriptionOption}
                step={props.step}
                globalPlusTests={props.globalPlusTests}
                deliveryOption={props.deliveryOption}
                guestCheckout={props.guestCheckout}
                subscriptionInfo={props.subscriptionInfo}
                cart={props.cart[0]}
                basketTestItems={props.basketTestItems}
                basketArray={props.cart[0].basketArray}
                phlebotomyOption={props.phlebotomyOption}
                voucher={props.voucher}
                user={props.user}
                partner={props.partner}
                nextDayDeliveryPrice={props.nextDayDeliveryPrice}
                partnerClinics={props.partnerClinics}
                basket={props.basket}
                tests={props.basketTestItems}
                updatePhlebotomyOption={props.updatePhlebotomyOption}
                phlebotomyError={props.phlebotomyError}
                updateDeliveryOption={props.updateDeliveryOption} 
                globalHCTests={props.globalHCTests}
              /> 
            </YourOrderWrapper> 
          </div>

        </div>
      </div>
    </>
  );
}