import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux"; 
import { RemoveFromBasket } from '../../../components'
import styles from "./QuantityToggle.module.scss"; 

export default function QuantityToggle(props) {  
 
  const reload = () => {
    // window.location.reload()
     window.location.pathname = `/checkout`
  }

  const changeQuantity = (value) => {
    let maxQuantity = 2;
    let minQuantity = 1;
    let hidePlus = false;
    let newQuantity = parseInt(props.quantity);
    
    if (props.test.subscriptionAvailable != 1 && props.step == 0 && props.phlebotomyOption != 2 && (props.customise || props.customise == "")) {
    
      if (props.grouped[0][0] != 152286 && props.grouped[0][0] != "152286") {
        maxQuantity = 10;
      }

      if (props.disableQuantity) {
        maxQuantity = 1;
      }

     
    } else {
      maxQuantity = 1;
      hidePlus = true; 
    }

    if (value == 'add') {
      if (newQuantity < maxQuantity) {
        newQuantity = parseInt(newQuantity) + 1; 
      }
    } else {
      if (newQuantity > minQuantity) {
        newQuantity = parseInt(newQuantity) - 1; 
      }
    }
    
    let showUpdateButton = true;

    if (props.grouped.length != newQuantity) {
      showUpdateButton = false;
    } 
    props.setQuantity(newQuantity);
    props.setShowUpdateButton(showUpdateButton); 
    props.handleQuantityChange(props.testId, newQuantity);
  }

  return ( 
    <div className={styles.quantityOptonArea+' justify-content-center  justify-content-md-start justify-content-lg-center'}>
      {props.quantityLoading ? 
      <div className="row ms-5">
        <div className="col">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      :
      <div id={styles.quantityCounter}> 
        <div className={styles.quantityOptonTitle + ' me-2'}>QTY</div>
        <div className={styles.number}>
          {props.quantity == 1 ? (
            <RemoveFromBasket small={true} reload={reload} phlebotomyOption={props.phlebotomyOption} testName={props.test.testName} testPrice={props.testPrice} testId={props.testId} partner={props.partner}/>
          ) : (
            <span onClick={() => { changeQuantity('remove') }} className={styles.minus}>-</span>
          )}
           
          <input type="text" readOnly value={props.quantity}/>
          
          {props.quantity < 10 && !props.disableQuantity && props.test.subscriptionAvailable != 1 && props.step == 0 && props.phlebotomyOption != 2 && (props.customise || props.customise == "")  && (
            <span onClick={() => { changeQuantity('add') }} className={styles.plus}>+</span>
          )} 
        </div>
      </div>}
    </div>   
  )
}



 