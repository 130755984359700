import React, {useState, useEffect} from 'react'
import {PostCodeLookUp, AddressForm, Button, Alert} from './../../../components'
import RenderIf from '../../RenderIf/RenderIf';
import styles from './DeliveryForm.module.scss'



export default function DeliveryForm(props) { 

  const [errorMessage, setErrorMessage] = useState("")
  const [showAddressArea, setShowAddressArea] = useState(false);
  const [initialAddress, setInitialAddress] = useState({});
  
  const handleReturnInitialAddress = (address) => {
    setInitialAddress({...address})
    setShowAddressArea(true)
  }

  useEffect(() => {
    if(props.customerDetails?.customerAddress1){
      setShowAddressArea(true)
      let houseNumber
      let address1

      if(props.customerDetails?.customerAddress1){
        if([","].includes(props.customerDetails.customerAddress1)){
          houseNumber = props.customerDetails.customerAddress1.split(",")[0]
          address1 = props.customerDetails.customerAddress1.split(",").slice(1).join(" ")
        }else{
          houseNumber = props.customerDetails.customerAddress1.split(" ")[0]

          if(houseNumber?.toLowerCase() == "flat"){
            houseNumber = props.customerDetails.customerAddress1.split(" ").slice(0, 2).join(" ")
            address1 = props.customerDetails.customerAddress1.split(" ").slice(2).join(" ")
          } else {
            houseNumber = props.customerDetails.customerAddress1.split(" ")[0]
            address1 = props.customerDetails.customerAddress1.split(" ").slice(1).join(" ")
          }
        }
      }

      props.setAddress({
        houseNumber: houseNumber?.replaceAll(",","")?.trim(),
        address1: address1?.trim(),
        address2: props.customerDetails?.customerAddress2 || "",
        address3: props.customerDetails?.customerAddress3 || "",
        town: props.customerDetails?.customerCity || "",
        postcode: props.customerDetails?.customerPostcode?.trim() || "",
        country: props.customerDetails?.customerCountry == "" ? "United Kingdom" : props.customerDetails?.customerCountry
      })
    }
  },[])

  return (
    <>
    <div className="row">
      <div className="col-12">
        <PostCodeLookUp handleReturnInitialAddress={handleReturnInitialAddress} />
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <RenderIf isTrue={!showAddressArea}>
          <Button variant="link" fontSize='md' className={`my-5 w-100 text-black`} onClick={() => setShowAddressArea(true)}>Add address manually</Button>
        </RenderIf>
      </div>
    </div>

    <RenderIf isTrue={showAddressArea}>
      <div className="row my-4">
        <div className="col-12">
          <AddressForm address={props.address} initialAddress={initialAddress} setAddress={props.setAddress} voucher={props.voucher}/>
          <Alert variant="danger">
            {errorMessage}
          </Alert>
        </div>
      </div>
    </RenderIf>
  </>
  )
}
