import React, { useEffect } from 'react'
import { InputText } from '../FormFields';
import styles from './AddressForm.module.scss'
import isEmpty from 'lodash/isEmpty'

export default function AddressForm(props) {

  const handleChange = ({target}) => {
    const {name, value} = target;

    props.setAddress({
      ...props.address,
      [name]: value
    })
  }

  useEffect(() => {
    if(!isEmpty(props.initialAddress)){
      let houseNumber = ""
      let address1 = ""
      let address2 = ""
      let address3 = ""
      let town = ""
      let postcode = ""
      let country = ""
      let numberToAddToAddress = "";
      let skipLine1 = false;

      const addressNumberChecker = /^(.*?\d\S*)/;
      let addressLineToCheck = props.initialAddress.addressline1.split(",")[0];
      let match = addressLineToCheck.match(addressNumberChecker);
      if (match) {  
        houseNumber = match[1]; 
      } else {
        houseNumber = props.initialAddress.addressline1.split(",")[0];
        skipLine1 = true;
      }
      
      if (props.initialAddress.addressline2) {
        let checkSecondLineForNumber = /\d.*?\d/;
        let numberFallbackCheck = /\d+/;
        let numberMatch = props.initialAddress.addressline2.split(",")[0].match(checkSecondLineForNumber)
        let numberMatchFallback = props.initialAddress.addressline2.split(",")[0].match(numberFallbackCheck)
        if (numberMatch) {
          numberToAddToAddress = numberMatch[0];
        } else if (numberMatchFallback) {
          numberToAddToAddress = numberMatchFallback[0];
        }
      }
      
      if (!skipLine1) {
        if(props?.initialAddress?.addressline1?.includes(",")){
          address1 = props.initialAddress.addressline1.split(",").slice(1).join(" ").replace(/[^a-zA-Z ]/g, "")
        } else if (props?.initialAddress?.addressline1?.includes(" ")) {
          address1 = props.initialAddress.addressline1.split(" ").slice(1).join(" ").replace(/[^a-zA-Z ]/g, "")
        } 
      }

      houseNumber = houseNumber?.replaceAll(",", "")?.trim();
      address2 = props?.initialAddress?.addressline2 ?? ""
      address3 = props?.initialAddress?.addressline3 ?? ""
      town = props?.initialAddress?.posttown ?? ""
      postcode = props?.initialAddress?.postcode ?? ""
      country = props?.initialAddress?.country ?? "United Kingdom"
      if (address1 == "") {
        address1 = props?.initialAddress?.addressline2 ?? ""
        address2 = ""
      }
      const address = {
        houseNumber: houseNumber,
        address1: numberToAddToAddress + address1.replace(/[^a-zA-Z ]/g, ""),
        address2: address2.replace(/[^a-zA-Z ]/g, ""),
        address3: address3.replace(/[^a-zA-Z ]/g, ""),
        town: town.replace(/[^a-zA-Z ]/g, ""),
        postcode: postcode,
        country: country.replace(/[^a-zA-Z ]/g, "")
      }

      props.setAddress && props.setAddress(address)
    }
  },[props.initialAddress])


  return (
    <div className={styles.addressForm}>
      <InputText 
        label="House name or number" 
        labelClassName="fw-normal mb-1" 
        className="mb-3 form-control" 
        name="houseNumber" 
        id="houseNumber"
        placeholder="Add here"
        value={props.address?.houseNumber} 
        onChange={handleChange}  
      />

      <InputText 
        label="Address line 1" 
        labelClassName="fw-normal mb-1" 
        className="mb-3 form-control" 
        name="address1" 
        id="address1"
        placeholder="Add here"
        value={props.address?.address1} 
        onChange={handleChange}  
      />

      <InputText 
        label="Address line 2" 
        labelClassName="fw-normal mb-1" 
        className="mb-3 form-control" 
        name="address2" 
        id="address2"
        placeholder="Add here"
        value={props.address?.address2} 
        onChange={handleChange}  
      />

      <InputText 
        label="Address line 3" 
        labelClassName="fw-normal mb-1" 
        className="mb-3 form-control" 
        name="address3" 
        id="address3"
        placeholder="Add here"
        value={props.address?.address3} 
        onChange={handleChange}  
      />

      <InputText 
        label="Town/City" 
        labelClassName="fw-normal mb-1" 
        className="mb-3 form-control" 
        name="town" 
        id="town"
        placeholder="Add here"
        value={props.address?.town} 
        onChange={handleChange}  
      />

      <InputText 
        label="Postcode" 
        labelClassName="fw-normal mb-1" 
        className="mb-3 form-control" 
        name="postcode" 
        id="postcode"
        placeholder="Add here"
        value={props.address?.postcode} 
        onChange={handleChange}  
      />

      <InputText 
        label="Country" 
        labelClassName="fw-normal mb-1" 
        className="mb-3 form-control" 
        name="country" 
        id="country"
        placeholder="Add here"
        value={props.address?.country} 
        onChange={handleChange}  
        disabled={props.voucher?.allowInternational ? false : true}
      />
    </div>
  );
}