import React, {useState, useEffect} from 'react'
import Button from '../../GlobalComponents/Button';
import styles from "./VoucherCode.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function VoucherCode(props) {  
  let basket = props.basket;  
  let stateVoucher = localStorage.getItem('voucher');
  let voucher = props.stateVoucher;
  if (!voucher) {
    voucher = JSON.parse(stateVoucher); 
  } 

  useEffect(() => { 
  }, [props]);
  

  const remove = (e) => {
    e.preventDefault();
    props.removeVoucher();
  }
  
  return (
    <> 
    {Object.keys(basket).length > 0 && (
      <div className="p-0 mt-3">
        <div className={styles.voucherArea}> 
          <form align="left" onSubmit={props.applyVoucherCode}>
            {(() => { 
              if (props.allowVoucher === false || (props.voucherStatus === true && (voucher && parseInt(voucher.affiliateId) != 0) && (voucher && voucher.discountAmount == 0.00))) {
                return '';
              } else { 
                if ((props.voucherStatus === true || localStorage.getItem('voucherStatus')) && voucher)  {
                  return (
                    <> 
                      <label htmlFor="voucherPill" className="fw-normal mb-2">Discount code:</label>
                      <div id="voucherPill" className="fw-normal text-dark">
                        <button onClick={remove} className={"btn m-0 mt-2 "+styles.btnVoucherCode}><FontAwesomeIcon icon={faTag} className='me-2' /> {voucher?.voucherCode && voucher?.voucherCode?.substring(0, 8)}{voucher.voucherCode.length > 8 ? "..." : ""} <FontAwesomeIcon icon={faTimes} className='ms-2' /></button>
                      </div>
                    </> 
                  ) 
                } else if (props.voucherStatus === '' || props.voucherStatus === false) {
                  return (
                    <>
                      <label htmlFor="voucherCode" className="fw-normal mb-1">Discount code:</label>
                      <div className="d-flex"> 
                        <input type="text" id="voucherCode" name="voucherCode" className={"form-control col inline mobileStackBottomMargin "+styles.voucherCode} placeholder="Discount code" />  
                        {/* <button type="submit" className="m-auto btn btn-primary-outline " id={styles.voucherCodeBtn} >Apply</button>  */}
                        <Button variant="primary-outline" id={styles.voucherCodeBtn} type="submit" className="px-3 col-auto">Apply</Button>
                      </div>
                      {props.voucherStatus === false && 
                        <div className={styles.phlebotomyError+ " mt-3"}>
                          <FontAwesomeIcon icon={faInfoCircle} /> Invalid Voucher
                        </div> 
                      } 
                    </>
                  )
                } 
              } 
            })()}
          </form>
        </div>
      </div>
    )}
    </>
  )
}
