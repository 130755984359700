import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBoxHeart, faMailbox} from "@fortawesome/pro-regular-svg-icons";

import {AddressForm, Alert, BasketItems, Button, CartSummary, CheckoutProgressBar, PostCodeLookUp, YourOrderWrapper, DeliveryForm} from '../../';
import calculateBasket from '../../../helpers/calculateBasket';
import runGA from '../../../helpers/runGA';
import RenderIf from '../../RenderIf/RenderIf';
import styles from './CheckoutDelivery.module.scss'


export default function CheckoutDelivery(props) {
  const [subtotal, setSubtotal] = useState(0);
  const [basketTotal, setBasketTotal] = useState();
  const [errorMessage, setErrorMessage] = useState("")
  const [showAddressArea, setShowAddressArea] = useState(false);
  const [address, setAddress] = useState({country: "United Kingdom"});
  const [initialAddress, setInitialAddress] = useState({});
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [testPrice, setTestPrice] = useState();

  useEffect(() => { 
    getValues(); 
  },[props]);

  const getValues = () => {
    let cartProps = {
      "user": props.user,
      "cart": props.cart[0],
      "tests": props.basketTestItems,
      "basket": props.cart[0].basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "nextDayDeliveryPrice": props.nextDayDeliveryPrice,
      "step": props.step
    } 
    
    let checkoutTotal = calculateBasket(cartProps); 
    setSubtotal(checkoutTotal.subtotal);
    setBasketTotal(checkoutTotal.testPrice);
    setTestPrice(checkoutTotal.testPrice);
  }

  const onSubmit = () => { 
    setErrorMessage("")

    if(!address.houseNumber){
      setErrorMessage("Please enter the house name or number")
      setIsNextButtonDisabled(false)
      return
    }

    if(!address.address1){
      setErrorMessage("Please enter the Address line 1")
      setIsNextButtonDisabled(false)
      return
    }

    if(!address.town){
      setErrorMessage("Please enter the Town")
      setIsNextButtonDisabled(false)
      return
    }

    if(!address.postcode){
      setErrorMessage("Please enter the Postcode")
      setIsNextButtonDisabled(false)
      return
    }

    if(!address.country){
      setErrorMessage("Please enter the Country")
      setIsNextButtonDisabled(false)
      return
    }
 
    props.setCustomerDetails({
      ...props.customerDetails,  
      customerAddress1: `${address.houseNumber}, ${address.address1}`, 
      customerAddress2: address.address2,
      customerAddress3: address.address3,
      customerCity: address.town,
      customerPostcode: address.postcode,
      customerCountry: address.country,
    })

    let updatePayload; 
    let guestCheckout = props.guestCheckout;
    let orderCookieId = localStorage.getItem("orderCookieId")
    if (props.guestCheckout == 'unset') {
      guestCheckout = false;
    }
    if (guestCheckout) {
      updatePayload = {
        customerFirstName: props.customerDetails.customerFirstName,
        customerLastName: props.customerDetails.customerLastName,
        customerEmailAddress: props.customerDetails.customerEmailAddress,
        customerHomeTelephone: props.customerDetails.customerHomeTelephone,
        customerDOBD: props.customerDetails.customerDOBD,
        customerDOBM: props.customerDetails.customerDOBM,
        customerDOBY: props.customerDetails.customerDOBY,
        customerSex: props.customerDetails.customerSex,
        customerEthnicity: 0,
        customerMenopausal: props.customerDetails.customerMenopausal,
        customerWeight: 0,
        customerWeightST: 0,
        customerWeightIB: 0,
        customerWeightType: 1,
        customerHeight: 0,
        customerHeightFT: 0,
        customerHeightIN: 0,
        customerHeightType: 1,
        customerAddress1: `${address.houseNumber} ${address.address1}`, 
        customerAddress2: address.address2,
        customerAddress3: address.address3,
        customerAddress4: "",
        customerCity: address.town,
        customerCountry: address.country,
        customerPostcode: address.postcode,
        customerOptIn: 0,
        customerMainGoal: 0,
        customerExercise: 0,
        customerPrimarySport: 0,
        customerOftenExercise: 0,
        customerTraining: "",
        customerHealthyDiet: 0,
        customerDiet: 0,
        customerAlcoholUnitsId: 0,
        customerSupliments: 0,
        customerSuplimentsDetails: 0,
        customerSuffer: 0,
        customerSufferDetails: 0,
        customerRegularPeriods: 0,
        customerContraception: 0,
        guestCustomerId: props.customerDetails.guestCustomerId,
        guestCustomer: true,
        doNotReview: 0,
        overideAndAllowFHM: props.user.overideAndAllowFHM,
        customerSexIdentify: 0,
        customerTransitioning: 0,
        customerTransitioningMedication: "",
        customerSmoke: 0,
        customerDiabetic: 0,
        customerDiabeticType: 0,
        customerAlcoholUnitsV2: 0,
        customerBloodPressure: "",
      };
    } else {
      updatePayload = {
        customerFirstName: props.customerDetails.customerFirstName,
        customerLastName: props.customerDetails.customerLastName,
        customerEmailAddress: props.customerDetails.customerEmailAddress,
        customerHomeTelephone: props.customerDetails.customerHomeTelephone,
        customerDOBD: props.customerDetails.customerDOBD,
        customerDOBM: props.customerDetails.customerDOBM,
        customerDOBY: props.customerDetails.customerDOBY,
        customerSex: props.customerDetails.customerSex,
        customerEthnicity: props.customerDetails.customerEthnicity,
        customerMenopausal: props.customerDetails.customerMenopausal,
        customerWeight: props.user.customerWeightKG ? props.user.customerWeightKG : 0,
        customerWeightST: props.user.customerWeightST ? props.user.customerWeightST : 0,
        customerWeightIB: props.user.customerWeightIB ? props.user.customerWeightIB : 0,
        customerWeightType: props.user.customerWeightType
          ? props.user.customerWeightType
          : 1,
        customerHeight: props.user.customerHeightM ? props.user.customerHeightM : 0,
        customerHeightFT: props.user.customerHeightFT ? props.user.customerHeightFT : 0,
        customerHeightIN: props.user.customerHeightIN ? props.user.customerHeightIN : 0,
        customerHeightType: props.user.customerHeightType
          ? props.user.customerHeightType
          : 1,
        customerAddress1: `${address.houseNumber} ${address.address1}`, 
        customerAddress2: address.address2,
        customerAddress3: address.address3,
        customerAddress4: props.user.customerAddress4 ? props.user.customerAddress4 : "",
        customerCity: address.town,
        customerCountry: address.country,
        customerPostcode: address.postcode,
        customerOptIn: props.customerDetails.customerOptIn,
        customerMainGoal: props.customerDetails.customerMainGoal
          ? props.customerDetails.customerMainGoal
          : 0,
        customerExercise: props.user.customerExerciseId
          ? props.user.customerExerciseId
          : 0,
        customerPrimarySport: props.user.customerPrimarySport
          ? props.user.customerPrimarySport
          : 0,
        customerOftenExercise: props.user.customerOftenExercise
          ? props.user.customerOftenExercise
          : 0,
        customerTraining: props.user.customerTraining ? props.user.customerTraining : "",
        customerHealthyDiet: props.user.healthyDietTypeId
          ? props.user.healthyDietTypeId
          : 0,
        customerDiet: props.user.dietTypeId ? props.user.dietTypeId : 0,
        customerAlcoholUnitsId: props.user.customerAlcoholUnitsId
          ? props.user.customerAlcoholUnitsId
          : 0,
        customerSupliments: props.user.customerSuplimentsId
          ? props.user.customerSuplimentsId
          : 0,
        customerSuplimentsDetails: props.user.customerSuplimentsDetails
          ? props.user.customerSuplimentsDetails
          : 0,
        customerSuffer: props.user.customerSufferId ? props.user.customerSufferId : 0,
        customerSufferDetails: props.user.customerSufferDetails
          ? props.user.customerSufferDetails
          : 0,
        customerRegularPeriods: props.user.customerRegularPeriods
          ? props.user.customerRegularPeriods
          : 0,
        customerAverageCycleLength: props.user.customerAverageCycleLength || "",
        customerContraception: props.user.customerContraception
          ? props.user.customerContraception
          : 0,
        customerContraceptionSelected:
          props.user.customerContraceptionSelected || "",
        customerContraceptionTypeOther:
          props.user.customerContraceptionTypeOther || "",
        customerIsPregnant: props.user.customerIsPregnant || 0,
        customerIsBreastfeeding: props.user.customerIsBreastfeeding || 0,
        customerHRT: props.user.customerHRT || 0,
        customerAdditionalMedicalInfo:
          props.user.customerAdditionalMedicalInfo || "",
        guestCustomerId: props.user.iD,
        orderCookieId: localStorage.getItem("orderCookieId"),
        doNotReview: props.user.doNotReview ? props.user.doNotReview : 0,
        overideAndAllowFHM: props.user.overideAndAllowFHM,
        checkoutFromPartner: props.customerDetails.checkoutFromPartner,
        customerId: props.customerDetails.checkoutFromPartner == 1 ? props.customerDetails.userId : undefined,
        customerSexIdentify: props.customerDetails.customerSexIdentify || props.user.customerSexIdentify,
        customerTransitioning: props.customerDetails.customerTransitioning || props.user.customerTransitioning,
        customerTransitioningMedication: props.customerDetails.customerTransitioningMedication || props.user.customerTransitioningMedication,
        customerSmoke: props.customerDetails.customerSmoke || props.user.customerSmoke,
        customerDiabetic: props.customerDetails.customerDiabetic || props.user.customerDiabetic,
        customerDiabeticType: props.customerDetails.customerDiabeticType || props.user.customerDiabeticType,
        customerAlcoholUnitsV2: props.customerDetails.customerAlcoholUnitsV2 || props.user.customerAlcoholUnitsV2,
        customerBloodPressure: props.customerDetails.customerBloodPressure || props.user.customerBloodPressure,
      };
    } 

    props.updateUserProfile(updatePayload).then((response) => {
      if (response.error) {
        setErrorMessage(response.error); 
      } else {
        setErrorMessage(''); 

        let partnerId;
        if (Object.keys(props.partner).length > 0) {
          partnerId = props.partner[0].partnerId;
        } 

        
        props.reloadUserData(); 
        props.updateOrderWithDeliveryOption(orderCookieId, props.deliveryOption, props.phlebotomyOption).then( response => {
          if(!response.error){
            
              props.goToStep(4);
         
           
          }
        })
        runGA("/checkout/payment");
     
      }
    }).finally(() => setIsNextButtonDisabled(false));


  }

  let isDisabled = true
  const {houseNumber, address1, town, postcode} = address

  if([houseNumber, address1, town, postcode].every( data => data != "" && data != undefined && data != undefined && data.replaceAll(" ", "") != "")){
    isDisabled = false
  }

  const hasFreeDelivery = props.deliveryInfo?.deliveryFee == 0

  return (
    <>
      <div className="row d-block d-md-none pt-0 pt-md-5 g-0 w-100">
        <div className="col-12">
          <YourOrderWrapper subtotal={subtotal} totalAmount={parseFloat(testPrice)} partner={props.partner} checkoutFromPartner={props.checkoutFromPartner} step={props.step}>
            <BasketItems
              cart={props.cart}
              basketTestItems={props.basketTestItems}
              phlebotomyOption={props.phlebotomyOption}
              getVoucher={props.getVoucher}
              globalPlusTests={props.globalPlusTests}
              setPlusTests={props.setPlusTests}
              handleQuantityChange={props.handleQuantityChange}
              subscriptionInfo={props.subscriptionInfo}
              subscriptionOption={props.subscriptionOption}
              step={props.step} 
              partner={props.partner} 
              voucher={props.voucher} 
              handleSubscriptionChange={props.handleSubscriptionChange}
              globalHCTests={props.globalHCTests}
              setGlobalHCTests={props.setGlobalHCTests}
            />
            <CartSummary
              sonlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
              deliveryInfo={props.deliveryInfo}
              customerDetails={props.customerDetails}
              phlebotomyPrices={props.phlebotomyPrices}
              voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
              subscriptionOption={props.subscriptionOption}
              step={props.step}
              globalPlusTests={props.globalPlusTests}
              deliveryOption={props.deliveryOption}
              guestCheckout={props.guestCheckout}
              subscriptionInfo={props.subscriptionInfo}
              cart={props.cart[0]}
              basketTestItems={props.basketTestItems}
              basketArray={props.cart[0].basketArray}
              phlebotomyOption={props.phlebotomyOption}
              voucher={props.voucher}
              user={props.user}
              partner={props.partner}
              nextDayDeliveryPrice={props.nextDayDeliveryPrice}
              partnerClinics={props.partnerClinics}
              basket={props.basket}
              tests={props.basketTestItems}
              updatePhlebotomyOption={props.updatePhlebotomyOption}
              phlebotomyError={props.phlebotomyError}
              updateDeliveryOption={props.updateDeliveryOption} 
              allowInternational={props?.stateVoucher?.allowInternational}
              globalHCTests={props.globalHCTests}
            /> 
          </YourOrderWrapper>
             
        </div>
      </div>

      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-md-6">
            <CheckoutProgressBar showQuestionAdditionalStep={props.foundEndoTest || props.foundTrueCheckTest} goToStep={props.goToStep}  step={props.step} checkoutFromPartner={props.checkoutFromPartner} skipStepOne={props.skipStepOne} guestCheckout={props.guestCheckout}/>

            <h2 className="text-center my-5">Delivery details</h2>

            <div className={`${styles.iconSection} mb-5`}>
              <div className="row justify-content-center">
                <RenderIf isTrue={hasFreeDelivery}>
                  <div className={`${styles.icon} col-6 text-center`}>
                    <FontAwesomeIcon icon={faBoxHeart} className='fa-fw pe-1' size="2x"/>
                    <p className="mt-2 mb-0">{props.deliveryOption == 1 ? "Next day delivery" : "Tracked 24 delivery & return"}</p>
                  </div>
                </RenderIf>
                <div className={`${styles.icon} col-6 text-center`}>
                  <FontAwesomeIcon icon={faMailbox} className="fa-fw pe-1" size="2x"/>
                  <p className="mt-2 mb-0">Fits through your letterbox</p>
                </div>
              </div>
            </div>

            <DeliveryForm voucher={props.voucher} address={address} setAddress={setAddress} customerDetails={props.customerDetails}/>

            <Button className={`w-100 ${isDisabled ? `${styles.disabled}` : ""}`} disabled={isNextButtonDisabled} onClick={() => {setIsNextButtonDisabled(true); onSubmit()}}>Next - Go to payment</Button>
          </div>

          <div className="col-12 col-md-6 d-none d-md-block"> 
            <YourOrderWrapper>
              <BasketItems
                cart={props.cart}
                basketTestItems={props.basketTestItems}
                phlebotomyOption={props.phlebotomyOption}
                getVoucher={props.getVoucher}
                globalPlusTests={props.globalPlusTests}
                setPlusTests={props.setPlusTests}
                handleQuantityChange={props.handleQuantityChange}
                subscriptionInfo={props.subscriptionInfo}
                subscriptionOption={props.subscriptionOption}
                step={props.step} 
                partner={props.partner} 
                voucher={props.voucher} 
                handleSubscriptionChange={props.handleSubscriptionChange}
                globalHCTests={props.globalHCTests}
                setGlobalHCTests={props.setGlobalHCTests}
              />
              <CartSummary 
                onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
                deliveryInfo={props.deliveryInfo}
                customerDetails={props.customerDetails}
                phlebotomyPrices={props.phlebotomyPrices}
                voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
                subscriptionOption={props.subscriptionOption}
                step={props.step}
                globalPlusTests={props.globalPlusTests}
                deliveryOption={props.deliveryOption}
                guestCheckout={props.guestCheckout}
                subscriptionInfo={props.subscriptionInfo}
                cart={props.cart[0]}
                basketTestItems={props.basketTestItems}
                basketArray={props.cart[0].basketArray}
                phlebotomyOption={props.phlebotomyOption}
                voucher={props.voucher}
                user={props.user}
                partner={props.partner}
                nextDayDeliveryPrice={props.nextDayDeliveryPrice}
                partnerClinics={props.partnerClinics}
                basket={props.basket}
                tests={props.basketTestItems}
                updatePhlebotomyOption={props.updatePhlebotomyOption}
                phlebotomyError={props.phlebotomyError}
                updateDeliveryOption={props.updateDeliveryOption} 
                allowInternational={props?.stateVoucher?.allowInternational}
                globalHCTests={props.globalHCTests}
              /> 
            </YourOrderWrapper> 
          </div>
        </div>
      </div>
    </>
  );
}